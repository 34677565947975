import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { container, button, Space, CustomSpin, Notification, card } from 'components/common';
import { Result, Button, Form, Input, Typography } from 'antd';
import styled from 'styled-components';
import * as dal from 'dal';
import { AxiosError } from 'axios';
import ROUTES from 'utils/constants/routes.json';
import { useTranslation } from 'react-i18next';

const { GradientContainer } = container;
const { DefaultButton } = button;
const { openNotificationWithIcon } = Notification;
const { LogoCard } = card;
const { Text } = Typography;

const StyledContainer = styled(GradientContainer)`
    .absolute {
        position: absolute;
        top: 20%;
    }
    form {
        width: 100%;
    }
    input {
        height: 60px;
    }
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
    }

    .logo-card {
        .ant-card-body {
            justify-content: center;
            align-items: center;
        }
    }
`;

const ChangePasswordView: React.FC = () => {
    const [hasToken, setHasToken] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resetToken, setToken] = useState('');
    const [message, setMessage] = useState('');

    const { t } = useTranslation(['passwordManagement']);
    const location = useLocation();
    const history = useHistory();
    const [form] = Form.useForm();

    const displayError = (err: AxiosError) => {
        switch (err.response?.data) {
            case 'MISSING_TOKEN':
                return t('passwordManagement:MISSING_TOKEN');
            case 'INVALID_TOKEN':
                return t('passwordManagement:INVALID_TOKEN');
            case 'USED_TOKEN':
                return t('passwordManagement:USED_TOKEN');
            case 'EXPIRED_TOKEN':
                return t('passwordManagement:EXPIRED_TOKEN');
            default:
                return ' ';
        }
    };

    useEffect(() => {
        const getTokenFromPathName = () => {
            const token = location.pathname.split('/').pop();
            if (
                token === undefined ||
                !/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(token)
            ) {
                setHasToken(false);
                return '';
            }
            setHasToken(true);
            setToken(token);
            return token;
        };

        const verifiedToken = getTokenFromPathName();
        if (verifiedToken) {
            setIsLoading(true);
            dal.auth
                .verifyToken(verifiedToken)
                .catch((err: AxiosError) => { setMessage(displayError(err)); setHasToken(false) })
                .finally(() => setIsLoading(false));
        }
    }, []);

    const onFinish = (values: any) => {
        setIsLoading(true);
        dal.auth
            .changePasswordWithToken({ password: values.password, token: resetToken })
            .then(() => history.push(ROUTES.password_changed))
            .catch((err: AxiosError) => openNotificationWithIcon({ type: 'error', message: displayError(err) }))
            .finally(() => setIsLoading(false));
    };

    return (
        <StyledContainer>
            <LogoCard>
                {isLoading && <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />}
                {!hasToken && (
                    <Result
                        className="reset-password-svg"
                        status="warning"
                        title={message}
                        extra={
                            <Space direction="column">
                                <Link to={ROUTES.reset_password}>
                                    <Button type="link" key="console">
                                        {t('passwordManagement:redirect_to_password_reset')}
                                    </Button>
                                </Link>
                                <Link to={ROUTES.base}>
                                    <Button type="link" key="console">
                                        {t('passwordManagement:redirect_to_base')}
                                    </Button>
                                </Link>
                            </Space>
                        }
                    />
                )}
                {hasToken && !isLoading && (
                    <Form form={form} name="register" layout="vertical" onFinish={onFinish} scrollToFirstError>
                        <Form.Item>{message && <Text type="danger">{message}</Text>}</Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t('passwordManagement:missing_password'),
                                },
                            ]}
                            hasFeedback>
                            <Input.Password placeholder={t('passwordManagement:password')} />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: t('passwordManagement:missing_confirm_password'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('passwordManagement:passwords_not_match')));
                                    },
                                }),
                            ]}>
                            <Input.Password placeholder={t('passwordManagement:confirm_password')} />
                        </Form.Item>
                        <Form.Item>
                            <DefaultButton loading={isLoading} htmlType="submit">
                                {t('passwordManagement:reset')}
                            </DefaultButton>
                        </Form.Item>
                    </Form>
                )}
            </LogoCard>
        </StyledContainer>
    );
};

export default ChangePasswordView;
