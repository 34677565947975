import React from 'react';
import { text, Space, container } from 'components/common';
import { useTranslation } from 'react-i18next';

const { Title } = text;
const { InformationContainer } = container;

const ConsentRegulationView: React.FC = () => {
    const { t } = useTranslation('regulation');
    return (
        <InformationContainer>
            <Space alignItems="center" direction="column">
                <Title level={1}>{t('consent', { returnObjects: true }).title}</Title>
            </Space>
            <Space direction="column">
                <>
                    <Title level={1}>{t('consent', { returnObjects: true }).subtitle} </Title>
                    {t('consent', { returnObjects: true }).content.map(c => (
                        <Title level={3}>{c}</Title>
                    ))}
                    {t('consent', { returnObjects: true }).footer.map(c => (
                        <Title level={2}>{c}</Title>
                    ))}
                </>
            </Space>
        </InformationContainer>
    );
};

export default ConsentRegulationView;
