import { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { openNotificationWithIcon } from 'components/common/Notification';
import { AuditSection } from 'components/features/audit';
import dal from 'dal';
import generateQueryString from 'utils/audit/generateQueryString';
import { useTranslation } from 'react-i18next';

const StyledRow = styled(Row)`
    width: 90vw;
    max-width: 900px;
    margin: 0 auto;
`;

const AuditView: React.FC = () => {
    const [audit, setAudit] = useState<PagedPortalAudit>({
        items: [],
        total: 0,
    });

    const [auditFilters, setAuditFilters] = useState<AuditFilters>({
        description: '',
        types: [],
        date: null,
        page: 1,
    });

    const { t } = useTranslation('audit');

    useEffect(() => {
        const getAudit = (queryString: string) => {
            dal.audit
                .get(queryString)
                .then(res => setAudit(res.data))
                .catch(() => openNotificationWithIcon({ type: 'error', message: t('fetch_error') }));
        };

        getAudit(generateQueryString(auditFilters));
    }, [auditFilters]);

    const handleFilter = (date: [Dayjs, Dayjs] | null, types: string[]) => {
        if (date) auditFilters.date = [date[0].format('YYYY-MM-DD'), date[1].add(1, 'd').format('YYYY-MM-DD')];

        if (types.length > 0) auditFilters.types = types;

        auditFilters.page = 1;

        setAuditFilters({ ...auditFilters });
    };

    const onPageChange = (nextPage: number) => {
        auditFilters.page = nextPage;
        setAuditFilters({ ...auditFilters });
    };

    const handleClear = () => {
        setAuditFilters({
            description: '',
            types: [],
            date: null,
            page: 1,
        });
    };

    return (
        <StyledRow>
            <Col span={24}>
                <AuditSection
                    auditEntries={audit}
                    handleClear={handleClear}
                    handleFilter={handleFilter}
                    auditFilters={auditFilters}
                    onPageChange={onPageChange}
                />
            </Col>
        </StyledRow>
    );
};

export default AuditView;
