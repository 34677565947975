import React from 'react';
import styled from 'styled-components';

type Props = {
    level?: 0 | 1 | 2 | 3;
    children?: React.ReactChild | React.ReactChild[];
};

const HO = styled.h1`
    font-weight: 400;
    color: ${props => props.theme.text.black};
`;

const H1 = styled.h1`
    font-size: 2.4em;
    color: ${props => props.theme.text.black};
`;

const H2 = styled.h2`
    font-size: 2em;
    color: ${props => props.theme.text.black};
`;
const H3 = styled.h3`
    font-size: 1.1em;
    color: ${props => props.theme.text.black};
`;

const Title: React.FC<Props> = ({ level = 1, children }: Props) => {
    const getLevel = () => {
        switch (level) {
            case 0:
                return <HO className="h0">{children}</HO>;
            case 1:
                return <H1>{children}</H1>;
            case 2:
                return <H2>{children}</H2>;
            case 3:
                return <H3>{children}</H3>;

            default:
                return <>{children}</>;
        }
    };
    return getLevel();
};

Title.defaultProps = {
    level: 1,
    children: <></>,
};

export default Title;
