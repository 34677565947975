import styled from 'styled-components';
import { Input, InputProps } from 'antd';

const StyledInput = styled(Input)`
    pointer-events: stroke;
    background: #eceff1;
    color: #9f9f9f;
`;

const ReadOnlyInput: React.FC<InputProps> = (props: InputProps) => (
    <StyledInput {...props} disabled>
        {props.children}
    </StyledInput>
);

export default ReadOnlyInput;
