import React from 'react';
import { SimpleTable } from 'components/common';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';

type Props = {
    dataSource: PortalAudit[];
    onRowSelect: (value: number | null) => void;
    choosenScreeningId: number | null;
};

// TODO: dopracować wyswietlanie daty w zaleznosci od docelowego obiektu z danymi DATASOURCE

const AuditTable: React.FC<Props> = ({ dataSource, onRowSelect, choosenScreeningId }) => {
    const { t, i18n } = useTranslation('audit');
    const columns = [
        {
            title: t('date'),
            key: 'createdAt',
            render: (record: PortalAudit) => {
                const createdAt = dayjs(record.createdAt).locale(i18n.language);
                return (
                    <>
                        <span>{createdAt.format('DD MMMM YYYY')}</span>
                        <p style={{ marginBottom: 0 }}>{createdAt.format('HH:mm')}</p>
                    </>
                );
            },
        },
        {
            title: t('type'),
            key: 'type',
            // @ts-ignore
            render: ({ type }: PortalAudit) => <p>{t('filter_types', { returnObjects: true })[type]}</p>
        },
        {
            title: t('description'),
            render: ({ type, ip }: PortalAudit) =>
                `${t(type)} ${i18n.language === 'pl' ? ' przez użytkownika o ip: ' : ' by user with ip: '}${ip}`,
        },
    ];

    return <SimpleTable columns={columns} dataSource={dataSource} />;
};

export default AuditTable;
