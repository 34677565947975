import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from 'components';
import { container, card } from 'components/common';
import { useTranslation } from 'react-i18next';
import { useStateContext } from 'store/state';
import { ActionType } from 'store/reducer';
import { getBrowserDetails } from 'utils/helpers/browser';
import * as dal from 'dal';
import placeholder from 'assets/img/login-placeholder.jpg';

const { BackgroundImageContainer } = container;
const { TextCard } = card;
const { LoginForm } = login;

type Props = {
    loginType: LoginType;
};

const COOKIES_STATUS = 3;

const LoginView: React.FC<Props> = ({ loginType }) => {
    const { dispatch } = useStateContext();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [background, setBackground] = useState<IBanner>({ url: placeholder });

    const { t } = useTranslation(['common', 'translation']);
    const history = useHistory();

    useEffect(() => {
        dal.banners
            .get('background')
            .then(res => res.data.length > 0 && setBackground(res.data[0]))
            .catch(() => setBackground({ url: placeholder }));
    }, []);

    const redirectToScreening = () => history.push(`/screening`);

    const redirectToPatientPortal = () => history.push(`/screenings`);

    const afterLoginAuditUserBrowserDetails = async () => {
        const { name, os, version } = getBrowserDetails();
        const {
            screen: { availHeight, availWidth },
        } = window;
        await dal.audit.saveClientBrowserDetails({
            browserName: name || '',
            browserVersion: version || '',
            os: os || '',
            resolutionX: availWidth,
            resolutionY: availHeight,
        });
    };

    const loginToResultPortal = async (values: ILoginAttempt) => {
        setLoading(true);
        await dal.auth
            .login(values)
            .then(() =>
                dispatch({
                    type: ActionType.SIGN_IN,
                })
            )
            .then(redirectToScreening)
            .then(afterLoginAuditUserBrowserDetails)
            .catch(err => {
                if (err?.response?.data?.status === COOKIES_STATUS) {
                    return setError(t('translation:cookies_error'));
                }
                return setError(t('translation:login_error'));
            })
            .finally(async () => setLoading(false));
    };

    const loginToPatientPortal = (values: ILoginAttempt) => {
        setLoading(true);
        dal.auth
            .loginAsPatient(values)
            .then(() =>
                dispatch({
                    type: ActionType.SIGN_IN,
                })
            )
            .then(redirectToPatientPortal)
            .then(afterLoginAuditUserBrowserDetails)
            .catch(err => {
                if (err?.response?.data?.status === COOKIES_STATUS) {
                    return setError(t('translation:cookies_error'));
                }
                return setError(t('translation:login_error'));
            })
            .finally(async () => setLoading(false));
    };

    const onLogin = (values: ILoginAttempt) => {
        if (loginType === 'resultPortal') {
            loginToResultPortal(values);
            return;
        }
        loginToPatientPortal(values);
    };

    return (
        <BackgroundImageContainer justifyContent="space-around" img={background.url as string}>
            <LoginForm loading={loading} onFinish={onLogin} error={error} type={loginType} />
            <TextCard
                title={t('translation:info_card_title')}
                content={<p>{t('translation:info_card_content')}</p>}
                footer={
                    <>
                        <p>
                            <em>{t('common:management_board')}</em> <br />
                        </p>
                    </>
                }
            />
        </BackgroundImageContainer>
    );
};

export default LoginView;
