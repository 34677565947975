import { api } from '../api';

export const login = (values: ILoginAttempt) => api.post('/auth/login', { ...values });

export const loginAsPatient = (values: ILoginAttempt) => api.post('/auth/portal/login', { ...values });

export const logout = () => api.get('/auth/logout');

export const changePassword = (values: any) => api.patch(`/auth/password`, { ...values });

export const changePasswordWithToken = (values: any) => api.patch(`/auth/password/token`, { ...values });

export const verifyToken = (token: string) => api.post(`/auth/password/token`, { token });

export const resetPassword = (values: any, language: string) =>
    api.post('/auth/password/reset', { ...values, language });
