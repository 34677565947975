import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

type Props = {
    icon?: React.ReactNode;
    customWidth?: string;
    children?: React.ReactChild | React.ReactChild[];
    onClick?: () => void;
};

const StyledButton = styled(Button)<Props>`
    width: ${props => props.customWidth || 'auto'};
    height: auto;
    display: flex;
    align-items: center;
    white-space: nowrap;
    word-wrap: break-word;
    color: ${props => props.theme.text.black};
    background-color: ${props => props.theme.background.theme};
    border-color: ${props => props.theme.background.border};
    svg {
        color: ${props => props.theme.text.black};
    }
    &:hover,
    &:focus {
        svg {
            color: ${props => props.theme.text.selected};
        }
        > span {
            color: ${props => props.theme.text.selected} !important;
        }
        border-color: ${props => props.theme.text.selected};
        color: ${props => props.theme.text.selected};
        background-color: ${props => props.theme.background.revertTheme};
    }
`;

const IconButton: React.FC<Props> = ({ children, icon, customWidth, onClick }: Props) => (
    <StyledButton onClick={onClick} customWidth={customWidth} icon={icon}>
        {children}
    </StyledButton>
);

IconButton.defaultProps = {
    children: <></>,
    icon: <DownloadOutlined />,
    onClick: () => {},
};

export default IconButton;
