import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Space, Typography, Row, Col } from 'antd';
import { container, button, FloatingBubble } from 'components/common';
import { pdf } from 'components/features';
import { useTranslation } from 'react-i18next';
import store from 'store';
import ROUTES from 'utils/constants/routes.json';
import CheckoutModal from 'components/checkout/CheckoutModal';
import { InterceptError } from 'utils/interceptors';
import { PrettyButton } from 'components/common/button';
import documentTypes from 'utils/constants/documentTypes.json';
import { downloadWykaz } from 'dal/screenings';
import styled from 'styled-components';
import dal from '../../dal';
import Doctor from '../../assets/img/doctor.png';

type Props = {
    screeningId?: number | null;
    fetchResult: (blob: any, filename: string) => void;
    fetchIsoInstruction: () => void;
    fetchReferralInformation: () => void;
    resultsPdfs: any;
    type: 'resultPortal' | 'patientPortal';
    hasSurvey: boolean;
    isoStatus: IsoStatus;
    fetchIso: () => void;
    generateIso: () => void;
    loadingState: LoadingState;
    portalProducts: IPortalProduct[];
};

const { IconButton, Button } = button;
const { ExaminationContainer } = container;
const { DownloadablePdfSection } = pdf;

const StyledCard = styled.div`
    border: 1px solid ${props => props.theme.background.selected || props.theme.background.pink};

    div {
        border: none;
    }
`;

const SingleScreeningView: React.FC<Props> = ({
    screeningId,
    fetchResult,
    fetchIsoInstruction,
    fetchReferralInformation,
    resultsPdfs,
    type,
    isoStatus,
    fetchIso,
    generateIso,
    hasSurvey,
    loadingState,
    portalProducts,
}) => {
    const [currentProduct, setCurrentProduct] = useState<IPortalProduct | null>(null);
    const [checkoutModalVisible, setCheckoutModalVisible] = useState<boolean>(false);
    const [checkoutModalLoading, setCheckoutModalLoading] = useState<boolean>(false);
    const [shop, setShop] = useState<string[]>([]);
    const [wykaz, setWykaz] = useState<any>();

    const { useStateContext } = store;
    const { state } = useStateContext();
    const {
        user: { personalData, email },
    } = state;

    const { t } = useTranslation(['examinationDetails', 'surveys', 'payments', 'buyConsultation']);

    const history = useHistory();

    useEffect(() => {
        const fetchWykaz = () => {
            downloadWykaz()
                .then(resp => resp.data)
                .then(data => setWykaz(data))
                .catch(console.log);
        };

        setShop(
            resultsPdfs
                .filter((r: any) => !r.file)
                .map((r: any) => {
                    if (r.name.toUpperCase().includes('EN')) return 'EN';
                    if (r.name.toUpperCase().includes('DE')) return 'DE';
                    return '';
                })
                .filter(Boolean)
        );

        fetchWykaz();
    }, [screeningId, resultsPdfs]);

    const redirectToSurveyView = () => {
        if (screeningId) return history.push(`/screening/${screeningId}/survey`);
        return history.push(ROUTES.survey);
    };

    const redirectToBuyConsultation = () => {
        if (screeningId) return history.push(`/buy-consultation/screening/${screeningId}`);
        return history.push(ROUTES.survey);
    };

    const displayIsoButton = (onClick: () => void, text: string, tip?: string) => (
        <Space direction="vertical">
            <div>
                <IconButton onClick={onClick}>{text}</IconButton>
            </div>
            {tip && (
                <span>
                    {tip}{' '}
                    <Button type="link" onClick={fetchIsoInstruction} style={{ margin: 0, padding: 0 }}>
                        {t('examinationDetails:support_materials')}.
                    </Button>
                </span>
            )}
        </Space>
    );

    const displayIsoAction = () => {
        switch (isoStatus) {
            case 'no_request':
                return displayIsoButton(
                    generateIso,
                    t('examinationDetails:generate_iso'),
                    t(
                        'examinationDetails:An ISO image is a photo registered from a mammography examination in DICOM (Digital Imaging and Communications in Medicine) and a program used for their publication. How to use it is available in sections'
                    )
                );
            case 'removed':
                return displayIsoButton(
                    generateIso,
                    t('examinationDetails:generate_iso'),
                    t(
                        'examinationDetails:An ISO image is a photo registered from a mammography examination in DICOM (Digital Imaging and Communications in Medicine) and a program used for their publication. How to use it is available in sections'
                    )
                );
            case 'done':
                return displayIsoButton(
                    fetchIso,
                    t('examinationDetails:download_iso_image'),
                    t(
                        'examinationDetails:An ISO image is a photo registered from a mammography examination in DICOM (Digital Imaging and Communications in Medicine) and a program used for their publication. How to use it is available in sections'
                    )
                );
            case 'in_progress':
            case 'new':
                return <Typography.Text>{t('examinationDetails:iso_request_accepted')}</Typography.Text>;
            default:
                return <></>;
        }
    };

    const handlePayment = (orderId: string) =>
        dal.order
            .makePayment(orderId, null)
            .then(res => {
                window.location.href = res.data.redirectUri;
            })
            .catch(() => {
                InterceptError(t('payments:pay_u_redirect_fail'));
            });

    const handleCreateOrder = (portalProduct: IPortalProduct) => {
        setCheckoutModalLoading(true);
        const screening = screeningId || undefined;
        const documentId = portalProduct.type.toUpperCase().includes('EN')
            ? resultsPdfs.find((r: any) => r.name.toUpperCase().includes('EN')).id
            : resultsPdfs.find((r: any) => r.name.toUpperCase().includes('DE')).id;
        dal.order
            .newOrder(portalProduct.id, documentId, screening)
            .then(res => handlePayment(res.data))
            .catch(err => {
                if (err.response.status === 400) return InterceptError(t('payments:order_exists'));
                return InterceptError(t('payments:order_exists'));
            })
            .finally(() => setCheckoutModalLoading(false));
    };

    const handleCloseCheckoutModal = () => {
        setCurrentProduct(null);
        setCheckoutModalVisible(false);
    };

    const handleDisplayCheckoutModal = (productTypeSubstring: string) => {
        const selectedProduct = portalProducts.find(p => p.type.includes(productTypeSubstring));
        if (selectedProduct) {
            setCurrentProduct(selectedProduct);
            setCheckoutModalVisible(true);
        }
    };

    const displayResultFiles = () =>
        resultsPdfs
            .filter((r: any) => r.type === documentTypes.SCREENING_DESCRIPTION_TYPE)
            .map((r: any) => {
                const { name, file } = r;

                if (name.toUpperCase().includes('EN')) {
                    if (file) {
                        return (
                            <DownloadablePdfSection
                                pdf={file}
                                downloadAction={() => fetchResult(file, name)}
                                downloadText={t('payments:result_download_desc')}
                                title={t('payments:engish_desc')}
                            />
                        );
                    }
                    return <></>;
                }

                if (name.toUpperCase().includes('DE')) {
                    if (file) {
                        return (
                            <DownloadablePdfSection
                                pdf={file}
                                downloadAction={() => fetchResult(file, name)}
                                downloadText={t('payments:result_download_desc')}
                                title={t('payments:german_desc')}
                            />
                        );
                    }
                    return <></>;
                }

                return file ? (
                    <DownloadablePdfSection
                        pdf={file}
                        downloadAction={() => fetchResult(file, name)}
                        downloadText={t('examinationDetails:download_result')}
                        title={t('examinationDetails:polish_desc')}
                    />
                ) : (
                    <></>
                );
            });

    const getReferral = () => resultsPdfs.find((r: any) => r.type === documentTypes.SCREENING_REFERRAL_TYPE);

    const displayReferralIfExists = () => {
        const referral = getReferral();
        if (!referral?.file) return <></>;

        return (
            <DownloadablePdfSection
                pdf={referral.file}
                downloadAction={() => fetchResult(referral.file, referral.name)}
                downloadText={t('examinationDetails:download_refferal')}
                title={t('examinationDetails:referral')}
            />
        );
    };

    const displayNoResultInfoIfNeeded = () => {
        if (resultsPdfs.filter((r: any) => r.type === documentTypes.SCREENING_DESCRIPTION_TYPE)?.length > 0)
            return <></>;

        return (
            <ExaminationContainer text={t('examinationDetails:study_descriptions')}>
                {t(
                    'examinationDetails:The description of your study is being prepared. Please check availability at a later date. The waiting time for the test result is a maximum of 15 business days.'
                )}
            </ExaminationContainer>
        );
    };

    const displayWykazIfExists = () => {
        if (!getReferral() || !wykaz) return <></>;

        return (
            <DownloadablePdfSection
                pdf={wykaz}
                downloadAction={() => fetchResult(wykaz, 'wykaz_placowek.pdf')}
                downloadText={t('examinationDetails:download_wykaz')}
                title={t('examinationDetails:List of facilities')}
            />
        );
    };

    const displayShop = () =>
        shop.map(s => {
            if (s === 'EN')
                return (
                    <Col>
                        <PrettyButton
                            style={{ margin: '10px' }}
                            label={t('payments:buy_english_desc')}
                            onClick={() => handleDisplayCheckoutModal(s)}
                        />
                    </Col>
                );
            if (s === 'DE')
                return (
                    <Col>
                        <PrettyButton
                            style={{ margin: '10px' }}
                            label={t('payments:buy_german_desc')}
                            onClick={() => handleDisplayCheckoutModal(s)}
                        />
                    </Col>
                );
            return <></>;
        });

    if (screeningId === null && type === 'patientPortal') return <></>;
    return (
        <>
            <FloatingBubble
                buttonText={t('buyConsultation:buy')}
                image={Doctor}
                textTitle={t('buyConsultation:title')}
                textContent={t('buyConsultation:content')}
                onClick={redirectToBuyConsultation}
                display={false} // Ukryte na życzenie klienta GTI-1984
            />

            {displayResultFiles()}
            {displayNoResultInfoIfNeeded()}
            {displayReferralIfExists()}
            {displayWykazIfExists()}
            {shop.length > 0 && (
                <ExaminationContainer text={t('payments:shop')}>
                    <Card loading={loadingState === 'screeningResult'}>
                        <Row>{displayShop()}</Row>
                    </Card>
                </ExaminationContainer>
            )}

            {isoStatus !== '' && (
                <ExaminationContainer text={t('examinationDetails:iso_image')}>
                    <StyledCard>
                        <Card loading={loadingState === 'iso'}>{displayIsoAction()}</Card>
                    </StyledCard>
                </ExaminationContainer>
            )}
            <ExaminationContainer text={t('examinationDetails:support_materials')}>
                <StyledCard>
                    <Card loading={loadingState === 'isoInstruction'}>
                        <Space direction="vertical" size="middle">
                            <Space direction="vertical">
                                <IconButton onClick={fetchIsoInstruction} customWidth="100%">
                                    {t('examinationDetails:Download instruction what to do with ISO image')}
                                </IconButton>
                                <span>{t('examinationDetails:Learn more about ISO')}</span>
                            </Space>
                            {getReferral() && (
                                <Space direction="vertical">
                                    <IconButton onClick={fetchReferralInformation} customWidth="100%">
                                        {t('examinationDetails:Referral information')}
                                    </IconButton>
                                    <span>{t('examinationDetails:Learn more about your referral')}</span>
                                </Space>
                            )}
                        </Space>
                    </Card>
                </StyledCard>
            </ExaminationContainer>
            <ExaminationContainer text={t('examinationDetails:surveys')}>
                <StyledCard>
                    <Card>
                        <Button onClick={redirectToSurveyView}>
                            {hasSurvey
                                ? t('surveys:view_survey_questionnaire')
                                : t('surveys:fill_survey_questionnaire')}
                        </Button>
                    </Card>
                </StyledCard>
            </ExaminationContainer>

            {currentProduct && (
                <CheckoutModal
                    product={currentProduct}
                    visible={checkoutModalVisible}
                    handlePayment={handleCreateOrder}
                    handleCancel={handleCloseCheckoutModal}
                    loading={checkoutModalLoading}
                    personalData={personalData}
                    email={email}
                />
            )}
        </>
    );
};

export default SingleScreeningView;
