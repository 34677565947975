import React from 'react';
import { Table } from 'components/common';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import { Tooltip } from 'antd';

type Props = {
    screenings: MammographicScreening[];
    onRowSelect: (value: number | null) => void;
    choosenScreeningId: number | null;
};

const MammographyTable: React.FC<Props> = ({ screenings, onRowSelect, choosenScreeningId }) => {
    const { t, i18n } = useTranslation(['table', 'common']);

    const displayResult = (result: number) => {
        switch (result) {
            case 1:
                return t('common:result_normal');
            case 2:
                return t('common:delicate_change');
            case 3:
                return t('common:possibly_delicate_change');
            case 4:
                return t('common:suspicious_change');
            case 5:
                return t('common:malcious_change');
            case -1:
                return t('common:needs_more_checks');

            default:
                return '';
        }
    };
    const columns = [
        {
            title: t('table:mammography', { returnObjects: true }).createdAt,
            key: 'createdAt',
            render: ({ screening }: MammographicScreening) => {
                const createdAt = dayjs(screening.createdAt).locale(i18n.language);
                return (
                    <>
                        <span>{createdAt.format('DD MMMM YYYY')}</span>
                        <p style={{ marginBottom: 0 }}>{createdAt.format('HH:mm')}</p>
                    </>
                );
            },
        },
        {
            title: t('table:mammography', { returnObjects: true }).screeningDescriptionsStatus,
            render: ({ screening }: MammographicScreening) =>
                screening.screeningDescriptionsStatus === 'desc_all_completed'
                    ? t('table:mammography', { returnObjects: true }).check.toUpperCase()
                    : t('table:mammography', { returnObjects: true }).during_desc.toUpperCase(),
        },
        {
            title: () => (
                <Tooltip title={t('table:mammography', { returnObjects: true }).scale}>
                    <span style={{ cursor: 'pointer' }}>{t('table:mammography', { returnObjects: true }).wynik}</span>
                </Tooltip>
            ),
            key: 'wynik',
            render: ({ descriptions }: MammographicScreening) => <>{displayResult(descriptions[0]?.wynik || -2)}</>,
        },
        {
            title: t('table:mammography', { returnObjects: true }).id,
            render: ({ screening }: MammographicScreening) => <>{screening.id || ''}</>,
            key: 'id',
        },
    ];

    return (
        <Table columns={columns} dataSource={screenings} onRowSelect={onRowSelect} choosenRowId={choosenScreeningId} />
    );
};

export default MammographyTable;
