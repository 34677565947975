import React, { useState } from 'react';
import { Card, Form, Input } from 'antd';
import { button, Space } from 'components/common';
import dal from 'dal';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { InterceptSuccess, InterceptError } from 'utils/interceptors';

const { DefaultButton } = button;

const ChangePasswordCard: React.FC = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation(['profileDetails', 'notifications']);

    const onFinish = (values: any) => {
        setLoading(true);
        dal.auth
            .changePassword(values)
            .then(() => {
                InterceptSuccess(t('profileDetails:password_success_change'));
            })
            .catch((err: AxiosError) => {
                switch (err.code) {
                    case '403':
                        InterceptError(t('profileDetails:invalid_password'));
                        break;
                    default:
                        InterceptError(t('notifications:error_occured'));
                        break;
                }
            })
            .finally(() => {
                form.resetFields();
                setLoading(false);
            });
    };
    return (
        <Card>
            <Form
                name="change-password"
                form={form}
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}>
                <Form.Item
                    name="oldPassword"
                    label={t('profileDetails:previous_password')}
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label={t('profileDetails:new_password')}
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                    hasFeedback>
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={t('profileDetails:confirm_new_password')}
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('profileDetails:password_not_match')));
                            },
                        }),
                    ]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Space direction="column" alignItems="center">
                        <DefaultButton loading={loading} htmlType="submit">
                            {t('profileDetails:change_password')}
                        </DefaultButton>
                    </Space>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default ChangePasswordCard;
