import { useEffect, useState } from 'react';
import { Upload, Row, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'components/common/button';
import { ExaminationContainer } from 'components/common/container';
import Title from 'components/common/text/Title';
import { InterceptError } from 'utils/interceptors';
import * as dal from '../dal';
import isDicomFile from '../utils/helpers/getDicmFiles';
import {CustomSpin} from "../components/common";

const StyledRow = styled(Row)`
    margin: 15px auto;
    padding: 0;
`;

const UploadDicomsView: React.FC<{}> = () => {
    const [files, setFiles] = useState<any[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);

    const { t } = useTranslation('dicom');

    const filesReady = () => files.length === files.filter((file: FileEntry) => file.status !== 'uploading').length;

    useEffect(() => {
        if (filesReady()) setUploading(false);
    }, [files]);

    const onChange = ({ file, fileList }: any) => setFiles(fileList.filter((f: any) => f.isDicom));
    
    const dummyRequest = ({ _, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const handleSubmit = () => {
        if (files.length === 0) return;
        setSending(true);
        const formData = new FormData();
        files.forEach((dicom: FileEntry) => formData.append('dicoms', dicom.originFileObj));
        dal.dicom
            .create(formData)
            .then(() => notification.success({ message: t('upload_success') }))
            .catch(() => InterceptError(t('upload_failure'), ''))
            .finally(() => {
                setFiles([]);
                setSending(false);
            });
    };

    const getButtonText = () => {
        if (uploading) return t('uploading');

        if (sending) return t('sending');

        return t('send');
    };
    
    const onUploading = (file: UploadedFile) => {
        if(!uploading) 
            setUploading(true);
        return isDicomFile(file);
    }

    return (
        <ExaminationContainer text="" action={<></>}>
            <StyledRow justify="center">
                <Title level={3}>{t('send_screenings')}</Title>
            </StyledRow>
            <StyledRow justify="center">
                <Upload
                    directory
                    fileList={files}
                    onChange={(e: any) => onChange(e)}
                    customRequest={dummyRequest}
                    beforeUpload={(file: UploadedFile) => onUploading(file)}>
                    <Button icon={<UploadOutlined />}>{t('add_folder')}</Button>
                </Upload>
            </StyledRow>
            <StyledRow justify="center">
                {!sending && !uploading || (files.length > 0 && filesReady()) 
                    ? <Button
                        disabled={sending || uploading || !(files.length > 0 && filesReady())}
                        loading={uploading}
                        onClick={handleSubmit}>
                        {getButtonText()}
                    </Button>
                    : <CustomSpin containerStyles={{ width: "100%" }} />}
               
            </StyledRow>
        </ExaminationContainer>
    );
};

export default UploadDicomsView;
