import React from 'react';
import { Radio, Col, Typography, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

type Props = {
    handleChange: (index: number, value: number, isDefinderOf: number) => void;
    question: string;
    index: number;
    definderOf: number;
    disabled: boolean | undefined;
    value?: number;
};

const NextQuestionGuard: React.FC<Props> = ({ question, handleChange, index, definderOf, disabled, value }: Props) => {
    const { t } = useTranslation('common');
    return (
        <Row justify="start" style={{ width: '100%' }}>
            {question && (
                <Col xs={{ span: 16, offset: 3 }} md={{ span: 8, offset: 2 }}>
                    <Typography.Title level={5}>{question}</Typography.Title>
                </Col>
            )}
            <Col xs={24} md={8}>
                <Row justify="center">
                    <Radio.Group
                        value={value}
                        onChange={e => handleChange(index, e.target.value, definderOf)}
                        disabled={disabled || false}>
                        <Radio value={1}>{t('true')}</Radio>
                        <Radio value={0}>{t('false')}</Radio>
                    </Radio.Group>
                </Row>
            </Col>
        </Row>
    );
};

NextQuestionGuard.defaultProps = {
    value: 0,
};

export default NextQuestionGuard;
