import React from 'react';
import { useLocalStorage } from 'hooks';
import { Button } from 'components/common/button';

const SizeSection: React.FC = () => {
    const [value, setValue] = useLocalStorage('size', '1');
    return (
        <>
            <Button
                type="link"
                style={{
                    borderBottom: value === '1' ? '2px solid #40A9FF' : '',
                }}
                onClick={() => setValue('1')}>
                A
            </Button>
            <Button
                type="link"
                style={{
                    borderBottom: value === '1.4' ? '2px solid #40A9FF' : '',
                }}
                onClick={() => setValue('1.4')}>
                A+
            </Button>
            <Button
                type="link"
                style={{
                    borderBottom: value === '1.6' ? '2px solid #40A9FF' : '',
                }}
                onClick={() => setValue('1.6')}>
                A++
            </Button>
        </>
    );
};

export default SizeSection;
