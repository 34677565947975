import React from 'react';
import styled from 'styled-components';

type Props = {
    img: string;
    justifyContent?: JustifyContent;
    children?: React.ReactChild | React.ReactChild[];
};

type ContainerProps = {
    img: string;
    justifyContent: JustifyContent;
};

const Container = styled.div<ContainerProps>`
    flex: 1;
    background-image: url(${(props: ContainerProps) => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: ${(props: ContainerProps) => props.justifyContent};
    align-content: center;
    flex-wrap: wrap;
    overflow: auto;
`;

const BackgroundImageContainer: React.FC<Props> = ({ children, img, justifyContent = 'center' }: Props) => (
    <Container className="background-image-container container" justifyContent={justifyContent} img={img}>
        {children}
    </Container>
);

BackgroundImageContainer.defaultProps = {
    children: <></>,
    justifyContent: 'center',
};

export default BackgroundImageContainer;
