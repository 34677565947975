import React from 'react';
import { Row } from 'antd';
import styled from 'styled-components';

type Props = {
    className?: string;
};

const Container = styled(Row)`
    width: 400px;

    @media screen and (max-width: 500px) {
        width: 90%;
        .ant-picker-panels {
            flex-direction: column;
        }
    }
`;

const PopoverRowContainer: React.FC<Props> = ({ children, className }) => (
    <Container className={className || ''}>{children}</Container>
);

export default PopoverRowContainer;
