import React, { useState } from 'react';
import { container } from 'components/common';
import { Card, Pagination, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuditTableFilters, AuditTable } from 'components/features/audit';
import { Dayjs } from 'dayjs';

const { ExaminationContainer } = container;

type Props = {
    auditEntries: PagedPortalAudit;
    handleFilter: (date: [Dayjs, Dayjs] | null, result: string[]) => void;
    handleClear: () => void;
    auditFilters: AuditFilters;
    onPageChange: (nextPage: number) => void;
};

const AuditSection: React.FC<Props> = ({
    auditEntries,
    handleFilter,
    handleClear,
    auditFilters,
    onPageChange,
}: Props) => {
    const [choosenId, setChoosenId] = useState<number | null>(null);
    const { t } = useTranslation('audit');

    return (
        <ExaminationContainer
            text={t('audit')}
            action={<AuditTableFilters handleFilter={handleFilter} handleClear={handleClear} />}>
            <Card>
                <Row style={{ width: '100%' }} gutter={[0, 32]}>
                    <Col span={24}>
                        <AuditTable
                            dataSource={auditEntries.items}
                            onRowSelect={(id: number | null) => setChoosenId(id)}
                            choosenScreeningId={choosenId}
                        />
                    </Col>
                    <Row justify="center" style={{ width: '100%' }}>
                        <Pagination
                            simple
                            total={auditEntries.total}
                            current={auditFilters.page}
                            onChange={(nextPage: number) => onPageChange(nextPage)}
                        />
                    </Row>
                </Row>
            </Card>
        </ExaminationContainer>
    );
};

export default AuditSection;
