import React from 'react';
import { Button } from 'components/common/button';
import { useLocalStorage } from 'hooks';
import { useTranslation } from 'react-i18next';

const ResetSection: React.FC = () => {
    const [_, setValue] = useLocalStorage('contrast', 'default');
    const [__, setSizeValue] = useLocalStorage('size', '1');
    const { i18n } = useTranslation();
    const onReset = () => {
        i18n.changeLanguage('pl');
        setValue('default');
        setSizeValue('1');
    };
    return (
        <Button style={{ background: 'white', borderColor: 'black', color: 'black' }} onClick={onReset}>
            Reset
        </Button>
    );
};

export default ResetSection;
