import React from 'react';
import { Button } from 'components/common/button';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'hooks';

const ChangeLanguageSection: React.FC = () => {
    const { i18n } = useTranslation();
    const [_, setValue] = useLocalStorage('lang', 'pl');
    return (
        <>
            <Button
                type="link"
                style={{ borderBottom: i18n.language === 'pl' ? '2px solid #40A9FF' : '' }}
                onClick={() => {
                    i18n.changeLanguage('pl');
                    setValue('pl');
                }}>
                PL
            </Button>
            <Button
                type="link"
                style={{ borderBottom: i18n.language === 'en' ? '2px solid #40A9FF' : '' }}
                onClick={() => {
                    i18n.changeLanguage('en');
                    setValue('en');
                }}>
                EN
            </Button>
        </>
    );
};

export default ChangeLanguageSection;
