import StyledTable from './StyledTable';

type Props = {
    dataSource: any;
    columns: any;
};

const SimpleTable: React.FC<Props> = ({ dataSource, columns }) => (
    <StyledTable dataSource={dataSource} columns={columns} pagination={false} />
);

export default SimpleTable;
