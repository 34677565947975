import { Slider } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useState } from 'react';

type IconSliderProps = {
    max: number;
    min: number;
    value: number | undefined;
    index: number;
    handleChange: (index: number, value: number) => void;
    disabled: boolean;
    reversed: boolean;
};

const SliderWrapper = styled.div`
    position: relative;
    padding: 0px 30px;

    .anticon {
        position: absolute;
        top: -15px;
        bottom: 0px;
        width: 16px;
        height: 16px;
        font-size: 40px;
        line-height: 1;
        right: -20px;
    }

    .initial .ant-slider-track {
        background-color: rgba(0, 0, 0, 0.25);
    }

    .changed .ant-slider-track {
        background-color: '#91d5ff';
    }
    .initial .ant-slider-handle {
        border-color: rgba(0, 0, 0, 0.25);
    }

    .changed .ant-slider-handle {
        border-color: '#91d5ff';
    }
`;

const IconSlider = ({ max, min, value, index, handleChange, disabled, reversed }: IconSliderProps) => {
    const [className, setClassName] = useState('initial');

    const handleSliderChange = (sliderValue: number) => {
        if (className === 'initial') setClassName('changed');
        handleChange(index, sliderValue);
    };

    const displayEmote = () => {
        const colorValues = ['#f5222d', '#fa8c16', '#fadb14', '#73d13d', '#389e0d'];

        if (!value) return <></>;

        if (reversed) {
            return value > 0 && value < 3 ? (
                <SmileOutlined style={{ color: colorValues.reverse()[value - 1] }} />
            ) : (
                <FrownOutlined style={{ color: colorValues.reverse()[value - 1] }} />
            );
        }

        return value > 0 && value < 3 ? (
            <FrownOutlined style={{ color: colorValues[value - 1] }} />
        ) : (
            <SmileOutlined style={{ color: colorValues[value - 1] }} />
        );
    };

    return (
        <SliderWrapper>
            <Slider
                max={max}
                min={min}
                onChange={(newValue: number) => handleSliderChange(newValue)}
                value={value}
                className={className}
                disabled={disabled}
            />
            {displayEmote()}
        </SliderWrapper>
    );
};

export default IconSlider;
