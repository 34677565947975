import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Card, Col, Divider, Row, Select } from 'antd';
import * as dal from 'dal';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';
import styled from 'styled-components';
import { container, CustomCheckbox, CustomSelect } from '../components/common';
import { useStateContext } from '../store/state';
import { InterceptError } from '../utils/interceptors';
import CustomSpin from '../components/common/CustomSpin';
import CheckoutModal from '../components/checkout/CheckoutModal';

const Container = styled.div`
    .bold {
        font-weight: 600;
    }
`;

const { Option } = Select;

const { ExaminationContainer } = container;

interface ParamTypes {
    id: string | undefined;
}

const BuyConsultationView: React.FC = () => {
    const [screening, setScreening] = useState<IScreening>();
    const [consultationRegistrations, setConsultationRegistrations] = useState<
        Array<Array<Array<IConsultationRegistrations>>>
    >([]);
    const [activeDateConsultation, setActiveDateConsultation] = useState<number>(100000);
    const [checkedRegistrationId, setRheckedRegistrationId] = useState<number>();
    const [portalProducts, setPortalProducts] = useState<IPortalProduct[]>([]);
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [currentProduct, setCurrentProduct] = useState<IPortalProduct | null>(null);
    const [checkoutModalVisible, setCheckoutModalVisible] = useState<boolean>(false);
    const [checkoutModalLoading, setCheckoutModalLoading] = useState<boolean>(false);

    const history = useHistory();
    const { id } = useParams<ParamTypes>();
    const { user } = useStateContext().state;

    const { personalData, email } = user;

    const { t, i18n } = useTranslation(['notifications', 'surveys', 'buyConsultation', 'payments']);

    const getResults = () =>
        dal.screenings
            .getScreeningById((id as unknown) as number)
            .then(res => {
                setScreening(res.data);
            })
            .catch(() => InterceptError(t('notifications:fetch_result_error')));

    const getConsultations = () =>
        dal.consultations
            .getAll()
            .then(res => {
                setConsultationRegistrations(res.data);
            })
            .catch(() => InterceptError(t('notifications:fetch_result_error')));

    const fetchProducts = () => {
        dal.products.getProducts().then(res => setPortalProducts(res.data));
    };

    const handleDisplayCheckoutModal = (productTypeSubstring: string) => {
        const selectedProduct = portalProducts.find(p => p.type.includes(productTypeSubstring));
        if (selectedProduct) {
            setCurrentProduct(selectedProduct);
            setCheckoutModalVisible(true);
        }
    };

    const handleCloseCheckoutModal = () => {
        setCurrentProduct(null);
        setCheckoutModalVisible(false);
        getConsultations();
    };

    const handlePayment = (orderId: string) =>
        dal.order
            .makePayment(orderId, checkedRegistrationId || 0)
            .then(res => {
                window.location.href = res.data.redirectUri;
            })
            .catch(() => {
                InterceptError(t('payments:pay_u_redirect_fail'));
            });

    const handleCreateOrder = (portalProduct: IPortalProduct) => {
        setCheckoutModalLoading(true);
        const screeningId = screening?.id as number;

        dal.order
            .newConsultationOrder(portalProduct.id, screeningId, checkedRegistrationId || 0, user?.personalDataId)
            .then(res => handlePayment(res.data))
            .catch(err => {
                const { data } = err.response;
                const message = data.status === 400 ? t('payments:pay_u_redirect_fail') : err.response.data;
                InterceptError(message);
            })
            .finally(() => {
                setCheckoutModalLoading(false);
                getConsultations();
            });
    };

    useEffect(() => {
        Promise.allSettled([getResults(), getConsultations(), fetchProducts()]).finally(() => setIsloading(false));
    }, []);

    const onCancel = () => {
        if (id) return history.push(ROUTES.mammography_screenings);

        return history.push(ROUTES.screening);
    };

    const handleCheckHour = (e: any) => {
        setRheckedRegistrationId(e.target.value);
    };

    const showConsultations = consultationRegistrations?.map((c, index) => (
        <Option value={index}>{dayjs(c[0][0]?.consultation?.date).locale(i18n.language).format('DD MMMM YYYY')}</Option>
    ));

    const showRegistrations = consultationRegistrations[activeDateConsultation]?.map(r => (
        <Row gutter={16}>
            <CustomCheckbox
                value={r[0].id}
                onChange={e => handleCheckHour(e)}
                checked={r[0].id === checkedRegistrationId}
                style={{ height: 30, padding: 20 }}>
                {r[0].startAt.slice(0, r[0].startAt.lastIndexOf(':'))}
            </CustomCheckbox>
        </Row>
    ));

    if (isLoading) return <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />;

    return (
        <Container>
            <ExaminationContainer text={t('buyConsultation:chooseDateConsulatation')}>
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col xl={3} className="bold">
                            {t('buyConsultation:fullName')}:
                        </Col>
                        <Col xl={3}>{`${user?.personalData?.firstName} ${user?.personalData?.lastName}`}</Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col xl={3} className="bold">
                            {t('buyConsultation:dateScreening')}
                        </Col>
                        <Col xl={3}>{dayjs(screening?.createdAt).locale(i18n.language).format('DD MMMM YYYY')}</Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]} className="bold">
                        {t('buyConsultation:chooseDateConsulatation')}
                    </Row>
                    <Row gutter={[16, 16]} className="bold">
                        <CustomSelect
                            style={{ width: '50%' }}
                            onChange={value => setActiveDateConsultation(value)}
                            placeholder={t('buyConsultation:placeholder')}>
                            {showConsultations}
                        </CustomSelect>
                    </Row>
                    <Row gutter={[16, 16]} className="bold">
                        <Col>{activeDateConsultation !== 100000 && showRegistrations}</Col>
                    </Row>
                </Card>
                <Row style={{ display: 'flex', justifyContent: 'space-between', width: '20%' }}>
                    <Button
                        className="accessibility-btn"
                        onClick={onCancel}
                        style={{ marginTop: '25px', height: 'auto' }}>
                        {t('surveys:cancel')}
                    </Button>
                    {checkedRegistrationId && (
                        <>
                            <Button
                                className="accessibility-btn"
                                onClick={() => handleDisplayCheckoutModal('CONSULTATION')}
                                style={{ marginTop: '25px', height: 'auto' }}>
                                {t('payments:pay')}
                            </Button>
                        </>
                    )}
                </Row>
                {currentProduct && (
                    <CheckoutModal
                        product={currentProduct}
                        visible={checkoutModalVisible}
                        handlePayment={handleCreateOrder}
                        handleCancel={handleCloseCheckoutModal}
                        loading={checkoutModalLoading}
                        personalData={personalData}
                        email={email}
                        descriptionPurchase={t('payments:consultation_purchase')}
                    />
                )}
            </ExaminationContainer>
        </Container>
    );
};

export default BuyConsultationView;
