import { Row, Col, Typography } from 'antd';

type Props = {
    title: string;
    description: string;
};

const SurveyHeader = ({ title, description }: Props) => (
    <Row justify="center">
        <Col span={24}>
            <Typography.Title level={3}>{title}</Typography.Title>
        </Col>
        <Col span={24}>
            <Typography.Paragraph>{description}</Typography.Paragraph>
        </Col>
    </Row>
);

export default SurveyHeader;
