import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, pl } from 'utils/locales';

// the translations
// (tip move them in a JSON file and import them)

export const resources = {
    en: {
        translation: en.translation,
        examinationDetails: en.examinationDetails,
        common: en.common,
        table: en.table,
        scales: en.scales,
        surveys: en.surveys,
        contactForm: en.contactForm,
        faq: en.faq,
        profileDetails: en.profileDetails,
        notifications: en.notifications,
        landingPage: en.landingPage,
        audit: en.audit,
        dicom: en.uploadDicom,
        passwordManagement: en.passwordManagement,
        regulation: en.regulation,
        payments: en.payments,
        buyConsultation: en.buyConsultation,
        cookies: en.cookies,
    },
    pl: {
        translation: pl.translation,
        examinationDetails: pl.examinationDetails,
        common: pl.common,
        table: pl.table,
        scales: pl.scales,
        surveys: pl.surveys,
        contactForm: pl.contactForm,
        faq: pl.faq,
        profileDetails: pl.profileDetails,
        notifications: pl.notifications,
        landingPage: pl.landingPage,
        audit: pl.audit,
        dicom: pl.uploadDidcom,
        passwordManagement: pl.passwordManagement,
        regulation: pl.regulation,
        payments: pl.payments,
        buyConsultation: pl.buyConsultation,
        cookies: pl.cookies,
    },
} as const;

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        defaultNS: 'common',
        ns: [
            'translation',
            'examinationDetails',
            'common',
            'scales',
            'table',
            'surveys',
            'contactForm',
            'faq',
            'profileDetails',
            'notifications',
            'landingPage',
            'audit',
            'passwordManagement',
            'regulation',
            'cookies'
        ],
        lng: 'pl',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
