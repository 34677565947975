import React from 'react';
import { text, container } from 'components/common';
import { useTranslation } from 'react-i18next';

const { Title } = text;
const { InformationContainer } = container;

const HelpView: React.FC = () => {
    const { t } = useTranslation('regulation');
    return (
        <InformationContainer>
            <>
                <Title>{t('regulation', { returnObjects: true }).title}</Title>
                {t('regulation', { returnObjects: true }).section.map(c => (
                    <>
                        <Title level={2}>{c.subtitle}</Title>
                        <>
                            {c.content.map(cc => (
                                <Title level={3}>{cc}</Title>
                            ))}
                        </>
                    </>
                ))}
            </>
        </InformationContainer>
    );
};

export default HelpView;
