import React from 'react';
import { Button } from 'antd';
import { useLocalStorage } from 'hooks';
import styled from 'styled-components';

type StyleProps = {
    background: string;
    border: string;
    color: string;
};
const StyledButton = styled(Button)<StyleProps>`
    background: ${props => props.background} !important;
    border-color: ${props => props.border} !important;
    color: ${props => props.color} !important;
    height: auto;
`;

const ContrastSection: React.FC = () => {
    const [_, setValue] = useLocalStorage('contrast', 'default');
    return (
        <>
            <StyledButton background="white" border="black" color="black" onClick={() => setValue('black-white')}>
                A
            </StyledButton>
            <StyledButton background="black" border="black" color="white" onClick={() => setValue('white-black')}>
                A
            </StyledButton>
            <StyledButton background="yellow" border="black" color="black" onClick={() => setValue('black-yellow')}>
                A
            </StyledButton>
            <StyledButton background="black" border="black" color="yellow" onClick={() => setValue('yellow-black')}>
                A
            </StyledButton>
        </>
    );
};

export default ContrastSection;
