export type CookiesType = {
    name: string;
    service: string;
    purpose: string;
    type: string;
};

type Tabs = {
    tab: 'Necessary';
    general: `Some cookies are necessary for the proper functioning of some sites. For this reason, their use does not require the user's consent.`;
    details: 'Necessary cookies are used to ensure the proper functioning of our portal. Since they are essential files, you cannot disable them.';
    data: CookiesType[];
};

export const NECESSARY: CookiesType[] = [
    {
        name: 'CookieConsent',
        service: 'Zgoda na pliki cookie',
        purpose: 'Zapamiętuje preferencje dotyczące plików cookie',
        type: 'Sesyjny plik cookie. Ważność: 365 dni',
    },
    {
        name: 'sessionId',
        service: 'Zgoda na pliki cookie',
        purpose: 'Utrzymuje bezpieczeństwo sesji podczas odwiedzin na stronie',
        type: 'Sesyjny plik cookie. Ważność: Usuwany po zamknięciu przeglądarki',
    }
];

export const TABS: Tabs[] = [
    {
        tab: 'Necessary',
        general: `Some cookies are necessary for the proper functioning of some sites. For this reason, their use does not require the user's consent.`,
        details:
            'Necessary cookies are used to ensure the proper functioning of our portal. Since they are essential files, you cannot disable them.',
        data: NECESSARY,
    },
];
