import React from 'react';
import StyledTable from './StyledTable';

type Props = {
    columns?: any[];
    dataSource?: any[];
    onRowSelect: (value: number | null) => void;
    choosenRowId?: number | null;
    locale?: any;
};

const Table: React.FC<Props> = ({ dataSource, columns, onRowSelect, choosenRowId, locale }: Props) => (
    <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={{
            simple: true,
            pageSize: 5,
            total: dataSource?.length,
        }}
        rowClassName={record => ((record as MammographicScreening).screening.id === choosenRowId ? 'selected-row' : '')}
        onRow={record => ({
            onClick: () => {
                const row = record as MammographicScreening;
                const isTheSameRecordClicked = row.screening.id === choosenRowId;
                onRowSelect(isTheSameRecordClicked ? null : row.screening.id);
            },
        })}
        locale={locale}
    />
);

Table.defaultProps = {
    columns: [],
    dataSource: [],
    choosenRowId: null,
};

export default Table;
