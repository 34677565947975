const polishNumber = new RegExp('^[0-9]{9}$'); // 123456789
const polishNumberWithAreaCode = new RegExp('^\\+48[0-9]{9}$'); // +48123456789

const anyNumber = new RegExp('^[0-9]{7,}$'); // minimum 7 ONLY digits
const anyNumberWithAreaCode = new RegExp('^\\+[0-9]{9,}$'); // + at the start and minimum 9 ONLY digits then

export const isPossiblyPhoneNumber = (phone: string) => [anyNumber, anyNumberWithAreaCode].some(reg => reg.exec(phone));

export const isPhoneNumberPolish = (phone: string) =>
    [polishNumber, polishNumberWithAreaCode].some(reg => reg.exec(phone));
