import React, { useState } from 'react';
import { Cookies } from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Row, Col, Checkbox, Button, Alert, Input } from 'antd';
import { InputMask } from '@react-input/mask';
import { useTranslation } from 'react-i18next';
import { card, form, button, Space } from 'components/common';
import CookiesModal from 'components/cookies/CookiesModal';
import CookiesAdjustment from 'components/cookies/CookiesAdjustment';
import ROUTES from 'utils/constants/routes.json';
import AppVersion from '../common/AppVersion';
import { useAppVersion } from '../../contexts/AppVersionContext';

const { LogoCard } = card;
const { Item } = form;
const { DefaultButton } = button;

const StyledForm = styled(Form)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .ant-checkbox-wrapper {
        margin: 0;
    }
    .ant-input::placeholder {
        color: #999;
        opacity: 1;
    }

    @media screen and (max-width: 767px) {
        a {
            button {
                margin: 0;
            }
        }
    }
`;

const StyledRow = styled(Row)`
    width: 100%;
`;

type Props = {
    onFinish: (values: ILoginAttempt) => void;
    error: string | null;
    type: LoginType;
    loading: boolean;
};

const LinkButton = styled(Button)`
    padding: 0;
    margin: 0;
    text-align: start;

    @media (min-width: 425px) {
        margin-left: 5px;
    }
`;

const LoginForm: React.FC<Props> = ({ onFinish, error, type, loading }) => {
    const { t } = useTranslation('translation');
    const { currentVersion } = useAppVersion();

    const [showCookiesWarning, setShowCookiesWarning] = useState<boolean>(true);
    const [showCookiesDetails, setShowCookiesDetails] = useState<boolean>(false);

    const doesNecessaryCookieExist = Cookies.get('CookieConsent');

    const handleShowDetails = () => {
        setShowCookiesWarning(false);
        setShowCookiesDetails(true);
    };

    const handleAcceptCookiesWarning = () => {
        Cookies.set('CookieConsent', true, { expires: 365, secure: true });
        setShowCookiesWarning(false);
    };

    const handleAcceptCookiesDetails = () => {
        Cookies.set('CookieConsent', true, { expires: 365, secure: true });
        setShowCookiesDetails(false);
    };

    return (
        <LogoCard>
            {!doesNecessaryCookieExist && showCookiesWarning && (
                <CookiesModal onShowDetails={handleShowDetails} onClose={handleAcceptCookiesWarning} />
            )}
            {!doesNecessaryCookieExist && showCookiesDetails && (
                <CookiesAdjustment onClose={handleAcceptCookiesDetails} />
            )}
            <StyledRow gutter={[0, 32]} justify="center">
                {error && (
                    <Col span={20}>
                        <Alert message={error} type="error" />
                    </Col>
                )}
                <Col span={20}>
                    <StyledForm
                        name="login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={formValues => onFinish(formValues as ILoginAttempt)}>
                        {type === 'resultPortal' && (
                            <>
                                <Form.Item
                                    name="accessCode"
                                    rules={[
                                        { required: true, message: `${t('error_not_user_provided')}` },
                                        {
                                            required: false,
                                            pattern: /^[a-zA-Z0-9]{1,8}$/,
                                            message: `${t('error_invalid_user')}`,
                                        },
                                    ]}>
                                    <InputMask
                                        mask="________"
                                        replacement="_"
                                        placeholder={t('access_code')}
                                        style={{ width: '100%', padding: 6 }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="birthAt"
                                    rules={[
                                        {
                                            required: true,
                                            pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                                            message: `${t('error_birth_date')}`,
                                        },
                                    ]}>
                                    <InputMask
                                        mask="yyyy-mm-dd"
                                        replacement={{ y: /\d/, m: /\d/, d: /\d/ }}
                                        placeholder={t('birth_date')}
                                        style={{ width: '100%', padding: 6 }}
                                    />
                                </Form.Item>
                            </>
                        )}
                        {type === 'patientPortal' && (
                            <>
                                <Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t('error_not_user_provided')}`,
                                        },
                                    ]}>
                                    <Input placeholder={t('email')} />
                                </Item>
                                <Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t('error_birth_date')}`,
                                        },
                                    ]}>
                                    <Input.Password placeholder={t('password')} />
                                </Item>
                            </>
                        )}
                        <Item
                            name="portalTerms"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    transform: value => value || undefined,
                                    type: 'boolean',
                                    message: `${t('error_consent_not_indicated')}`,
                                },
                            ]}>
                            <Checkbox>
                                {t('accept_terms')}
                                <Link to={ROUTES.portal_regulations}>
                                    <LinkButton type="link">{t('portal_terms')}</LinkButton>
                                </Link>
                            </Checkbox>
                        </Item>
                        <Item
                            name="policyTerms"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    transform: value => value || undefined,
                                    type: 'boolean',
                                    message: `${t('error_consent_not_indicated')}`,
                                },
                            ]}>
                            <Checkbox>
                                {t('accept_terms')}
                                <Link to={ROUTES.portal_consent}>
                                    <LinkButton type="link">{t('policy_terms')}</LinkButton>
                                </Link>
                            </Checkbox>
                        </Item>
                        <Item>
                            <Space direction="column" alignItems="center" style={{ gap: '8px' }}>
                                <DefaultButton loading={loading} htmlType="submit">
                                    {t('button_login')}
                                </DefaultButton>
                                <Space direction="row" alignItems="center" justifyContent="center">
                                    <p style={{ margin: 0 }}> {t('do_have_problem')} </p>
                                    <Link to={ROUTES.faq}>
                                        <Button type="link">{t('click_here')}</Button>
                                    </Link>
                                </Space>
                                <Space direction="row" alignItems="baseline" justifyContent="center">
                                    {type === 'patientPortal' ? (
                                        <Link to={ROUTES.reset_password}>
                                            <Button type="link">{t('forgot_password')}</Button>
                                        </Link>
                                    ) : (
                                        <></>
                                    )}
                                </Space>
                            </Space>
                        </Item>
                    </StyledForm>
                </Col>
                <AppVersion version={currentVersion} />
            </StyledRow>
        </LogoCard>
    );
};

export default LoginForm;
