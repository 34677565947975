import { Modal, Tabs } from 'antd';
import Button from 'components/common/button/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import generateRandomKey from 'utils/helpers/generateRandomKey';
import CookieTab from './CookieTab';
import { TABS } from './details';

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    button {
        width: auto;
        height: 40px;
    }
`;

const Container = styled.div`
    width: 100%;
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background-color: ${props => props.theme.background.theme} !important;
    }
`;

type Props = {
    onClose: () => void;
};

const CookiesAdjustment = ({ onClose }: Props) => {
    const { t } = useTranslation('cookies');
    const { TabPane } = Tabs;

    return (
        <StyledModal visible centered closable={false} footer={null} width="95%">
            <Container>
                <Tabs defaultActiveKey="0" tabPosition="left">
                    {TABS.map(({ tab, general, details, data }) => (
                        <TabPane key={generateRandomKey()} tab={t(tab)}>
                            <CookieTab key={tab} tab={t(tab)} general={t(general)} details={t(details)} data={data} />
                        </TabPane>
                    ))}
                </Tabs>
                <ButtonsContainer>
                    <Button onClick={onClose}>{t('Save and exit')}</Button>
                </ButtonsContainer>
            </Container>
        </StyledModal>
    );
};

export default CookiesAdjustment;
