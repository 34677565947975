import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { container, CustomSpin } from 'components/common';
import { refferal, pdf } from 'components/features';
import dal from 'dal';
import { download } from 'utils/helpers/download';
import { InterceptError } from 'utils/interceptors';
import dayjs, { Dayjs } from 'dayjs';

const { RefferalTable, ReferralTableFilters } = refferal;
const { ExaminationContainer } = container;
const { DownloadablePdfSection } = pdf;

const ReferralView: React.FC = () => {
    const [screenings, setScreenings] = useState<MammographicScreening[]>([]);
    const [choosenScreeningId, setChoosenScreeningId] = useState<number | null>(null);
    const [referralPdf, setReferralPdf] = useState(null);
    const [loadingState, setLoadingState] = useState<string>('');

    const { t } = useTranslation(['examinationDetails', 'notifications']);

    const fetchRefferals = () => {
        setLoadingState('refferals');
        dal.screenings
            .getRefferals()
            .then(res => res.data)
            .then(res => setScreenings(res))
            .finally(() => setLoadingState(''));
    };

    useEffect(() => {
        fetchRefferals();
    }, []);

    useEffect(() => {}, [choosenScreeningId]);

    useEffect(() => {
        const fetchRefferal = () => {
            setLoadingState('referal');
            dal.screenings
                .getRefferalById(choosenScreeningId as number)
                .then(res => setReferralPdf(res.data))
                .catch(() => InterceptError(t('notifications:fetch_refferal_pdf_error')))
                .finally(() => setLoadingState(''));
        };
        if (choosenScreeningId !== null)
            fetchRefferal();
    }, [choosenScreeningId]);

    const handleFilter = (date: [Dayjs, Dayjs] | null) => {
        const filtered = screenings.filter(entry => {
            if (date) {
                const formatted = dayjs(entry.screening.createdAt).format('MM/DD/YYYY');

                if (
                    !(
                        dayjs(formatted).diff(date[0].format('MM/DD/YYYY')) >= 0 &&
                        dayjs(formatted).diff(date[1].format('MM/DD/YYYY')) <= 0
                    )
                )
                    return false;
            }
            return true;
        });

        setScreenings(filtered);
    };

    const handleClear = () => fetchRefferals();

    return (
        <>
            {loadingState === 'referrals' ? (
                <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />
            ) : (
                <ExaminationContainer
                    text={t('examinationDetails:referral')}
                    action={<ReferralTableFilters handleFilter={handleFilter} handleClear={handleClear} />}>
                    <Card>
                        <RefferalTable
                            screenings={screenings}
                            onRowSelect={(id: number | null) => setChoosenScreeningId(id)}
                            choosenId={choosenScreeningId}
                        />
                    </Card>
                </ExaminationContainer>
            )}
            {loadingState === 'referral' ? (
                <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />
            ) : (
                referralPdf && (
                    <DownloadablePdfSection
                        downloadAction={() => download(referralPdf, 'skierowanie.pdf')}
                        pdf={referralPdf}
                        title={t('examinationDetails:referral')}
                        downloadText={t('examinationDetails:download_refferal')}
                    />
                )
            )}
        </>
    );
};

export default ReferralView;
