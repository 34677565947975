import { useState } from 'react';
import { Row, Col, Input, Radio, Divider, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';
import { useTranslation } from 'react-i18next';
import { DatePicker, Popover, button, container } from 'components/common';
import { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { ResultCheckbox } from './MammographyFilters';

const { PrettyButton, Button } = button;
const { PopoverRowContainer } = container;

const StyledClearFiltersBtn = styled(Button)`
span {
    color: #f5222d !important
}`

const StyledFilterBtn = styled(Button)`
span {
    color: #1890ff !important
}`

const StyledCancelBtn = styled(Button)`
span {
    color: #8c8c8c !important
}`

type Props = {
    handleFilter: (
        date: [Dayjs, Dayjs] | null,
        descriptionFinished: string,
        result: number[],
        screeningId: string
    ) => void;
    handleClear: () => void;
};

const MammographyTableFilters = ({ handleFilter, handleClear }: Props) => {
    const [date, setDate] = useState<[any, any] | null>(null);
    const [descriptionFinished, setDescriptionFinished] = useState<'yes' | 'no' | ''>('');
    const [result, setResult] = useState<number[]>([]);
    const [screeningId, setScreeningId] = useState<string>('');
    const [visible, setVisible] = useState(false);

    const [t, i18n] = useTranslation('common');

    const clearFilters = () => {
        setDate(null);
        setDescriptionFinished('');
        setResult([]);
        setScreeningId('');
    };

    const onCancel = () => {
        clearFilters();
        setVisible(false);
    };

    const onClear = () => {
        clearFilters();
        setVisible(false);
        handleClear();
    };

    const onFilter = () => {
        setVisible(false);
        handleFilter(date, descriptionFinished, result, screeningId);
    };

    const content = (
        <PopoverRowContainer className="popover-content">
            <Row justify="space-between" style={{ width: '100%' }}>
                <Col xs={24} sm={6}>
                    <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c', marginRight: '40px' }}>
                        {t('date')}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={18}>
                    {i18n.language === 'pl' ? (
                        <DatePicker.RangePicker
                            value={date || undefined}
                            locale={locale}
                            onChange={dates => setDate(dates)}
                        />
                    ) : (
                        <DatePicker.RangePicker value={date || undefined} onChange={dates => setDate(dates)} />
                    )}
                </Col>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c', marginRight: '40px' }}>
                    {t('is_signed')}
                </Typography.Text>
                <Col xs={24} sm={12}>
                    <Radio.Group value={descriptionFinished} onChange={e => setDescriptionFinished(e.target.value)}>
                        <Radio value="yes">{t('true')}</Radio>
                        <Radio value="no">{t('false')}</Radio>
                    </Radio.Group>
                </Col>

                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Col>
                    <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c' }}>{t('result')}</Typography.Text>
                    <ResultCheckbox onSelect={setResult} selected={result} />
                </Col>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Col xs={24} sm={6}>
                    <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c', marginRight: '40px' }}>
                        {t('screening_id')}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={18}>
                    <Input
                        value={screeningId}
                        onChange={e => setScreeningId(e.target.value)}
                        placeholder="Id badania"
                        style={{ width: '100px' }}
                    />
                </Col>
                <Divider style={{ marginTop: '10px', marginBottom: '0' }} />
                <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={14}>
                        <StyledClearFiltersBtn type="link" onClick={clearFilters}>
                            {t('clear_filters')}
                        </StyledClearFiltersBtn>
                    </Col>
                    <>
                        <StyledCancelBtn type="link" onClick={onCancel}>
                            {t('cancel')}
                        </StyledCancelBtn>
                        <StyledFilterBtn type="link" onClick={onFilter}>
                            {t('filter')}
                        </StyledFilterBtn>
                    </>
                </Row>
            </Row>
        </PopoverRowContainer>
    );

    return (
        <Row>
            <Popover
                content={content}
                className="filter-menu"
                trigger="click"
                visible={visible}
                onVisibleChange={setVisible}
                placement="bottom">
                <PrettyButton label={t('filter')} />
            </Popover>
            <Button type="link" onClick={onClear}>
                {t('clear')}
            </Button>
        </Row>
    );
};

export default MammographyTableFilters;
