const verifyAnonymousPathAllowed = (anonymousPaths: string[], pathname: string) => {
    let isAnonymous = anonymousPaths.indexOf(pathname) !== -1;

    anonymousPaths.forEach(p => {
        if (p.includes('/:')) {
            const result = p
                .split('/')
                .filter(value => pathname.split('/').includes(value))
                .filter(path => path !== '');

            if (result.length) isAnonymous = true;
        }
    });

    return isAnonymous;
};

export { verifyAnonymousPathAllowed };
