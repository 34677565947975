import React from 'react';
import { Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from 'components/common';

type Props = {
    onSelect: (values: string[]) => void;
    selected: string[];
};

const AuditTypeCheckbox: React.VFC<Props> = ({ onSelect, selected }: Props) => {
    const { t } = useTranslation('audit');

    const displayOptions = () => {
        const values = [
            { value: ['SCREENING', 'SCREENING_UNAUTHORIZED'].join('%'), title: t('screening') },
            {
                value: ['RESULT', 'RESULT_UNAUTHORIZED', 'REFFERENCE', 'REFFERENCE_UNAUTHORIZED'].join('%'),
                title: t('documents'),
            },
            { value: ['ISO', 'ISO_GENERATE', 'ISO_GENERATE_UNAUTHORIZED'].join('%'), title: t('iso') },
            { value: ['LOGIN_RESULT', 'LOGIN_PORTAL', 'LOGIN_PORTAL_UNAUTHORIZED'].join('%'), title: t('login') },
        ];

        return values.map(({ value, title }) => (
            <Col xs={24} sm={12}>
                <Checkbox key={`${value}-${title}`} value={value}>
                    {title}
                </Checkbox>
            </Col>
        ));
    };
    return <CheckboxGroup onSelect={onSelect} selected={selected} options={displayOptions()} />;
};

export default AuditTypeCheckbox;
