import styled from 'styled-components';

const Container = styled.div`
    flex: 1;
    background-color: #b8c6db;
    ${props =>
        props.theme.background.theme
            ? `
            background-color: ${props.theme.background.theme};
            `
            : `
      background-color: #b8c6db;
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    `}
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export default Container;
