import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import { useLocalStorage } from 'hooks';
import Header from './Header/Header';
import Content from './Content';
import AppVersion from '../common/AppVersion';
import { useAppVersion } from '../../contexts/AppVersionContext';

type Props = {
    guest: boolean;
    children?: React.ReactChild | React.ReactChild[];
};

const StyledLayout = styled(AntLayout)`
    overflow-y: auto;
    background-color: ${props => props.theme.background.theme};

    .app-version {
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
    }
`;

const Layout: React.FC<Props> = ({ children, guest }: Props) => {
    const { currentVersion } = useAppVersion();
    const [value] = useLocalStorage('size', '1');

    return (
        <StyledLayout style={{ paddingTop: `${110 * Number(value)}px` }}>
            <Header guest={guest} />
            <AppVersion version={currentVersion} />
            <Content guest={guest}>{children}</Content>
        </StyledLayout>
    );
};

Layout.defaultProps = {
    children: <></>,
};

export default Layout;
