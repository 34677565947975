import React from 'react';
import styled from 'styled-components';

type Props = {
    onClick?: () => void;
    label?: string;
    style?: any;
};

const StyledButton = styled.button`
    background-color: ${props => props.theme.background.apricot};
    border: 2px solid ${props => props.theme.background.apricot};
    border-radius: 8px;
    padding: 3px 10px 3px 10px;
    color: ${props => props.theme.text.lavender};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.background.peach};
        color: ${props => props.theme.background.theme};
    }
`;

const PrettyButton: React.FC<Props> = ({ onClick, label, style }) => (
    <StyledButton onClick={onClick} style={style}>
        {label}
    </StyledButton>
);

export default PrettyButton;
