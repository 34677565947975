import { DownOutlined } from '@ant-design/icons';
import { Collapse, Modal, Switch } from 'antd';
import { Button } from 'components/common/button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import logo from 'assets/img/logo.png';
import generateRandomKey from 'utils/helpers/generateRandomKey';
import { TABS } from './details';

const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        margin: 0;
    }
`;

const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    display: flex;

    button {
        margin: 12px 0;
    }
`;

const MainContent = styled.p`
    margin: 25px 0;
    font-size: 0.9em;
`;

const CollapseContainer = styled.div`
    width: 100%;
`;

const Logo = styled.img`
    width: auto;
    height: 30px;
`;

const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    gap: 10px;

    button {
        width: 80%;
        height: 40px;
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background-color: ${props => props.theme.background.theme} !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: ${props => props.theme.background.revertTheme || 'inherit'};
    }
`;

type Props = {
    onClose: () => void;
    onShowDetails: () => void;
};

const CookiesModal = ({ onShowDetails, onClose }: Props) => {
    const { t } = useTranslation('cookies');
    const { Panel } = Collapse;
    return (
        <StyledModal visible centered closable={false} footer={null}>
            <ContainerHeader>
                <div>
                    <b>{t('Consent to cookies and data processing')}</b>
                </div>
                <div>
                    <Logo src={logo} alt="logo" />
                </div>
            </ContainerHeader>
            <MainContent>
                {t(
                    "Our site uses cookies, which are stored on the disk of the user's terminal device for statistical purposes and facilitate the use of our site. These settings can always be change, except for essential files for which consent is mandatory. Below you will find information about which files we use. It is up to You to decide which files you accept. You can change your mind at any time and change your choice using the Customize button."
                )}
            </MainContent>
            <ContainerColumn>
                {TABS.map(({ tab, general }) => (
                    <Container>
                        <Switch defaultChecked disabled />
                        <CollapseContainer>
                            <Collapse
                                ghost
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}>
                                <Panel header={t(tab)} key={generateRandomKey()}>
                                    <p>{t(general)}</p>
                                </Panel>
                            </Collapse>
                        </CollapseContainer>
                    </Container>
                ))}
            </ContainerColumn>
            <ButtonsContainer>
                <Button onClick={onClose}>{t('Allow all')}</Button>
                {/* <Button onClick={onClose}>{t('Reject all')}</Button> jak dojdą opcjonalne ciasteczka, wtedy można odkomentować */}
                <Button onClick={onShowDetails}>{t('Adjust')}</Button>
            </ButtonsContainer>
        </StyledModal>
    );
};

export default CookiesModal;
