import * as auth from './auth';
import * as banners from './banners';
import * as screenings from './screenings';
import * as surveys from './surveys';
import * as iso from './iso';
import * as contact from './contact';
import * as user from './user';
import * as dicom from './dicom';
import * as audit from './audit';
import * as products from './products';
import * as order from './order';
import * as consultations from './consultations';

const dal = {
    auth,
    banners,
    screenings,
    iso,
    surveys,
    contact,
    user,
    dicom,
    audit,
    products,
    order,
    consultations
};

export { auth, banners, screenings, iso, surveys, contact, user, dicom, audit, products, order, consultations };
export default dal;
