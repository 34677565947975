import { Switch } from 'antd';
import { SimpleTable } from 'components/common';
import Table from 'components/common/table/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CookiesType } from './details';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 1.2em;
    }

    .main-description {
        font-size: 0.9em;
    }

    h2 {
        font-size: 1em;
    }
`;

type Props = {
    tab: string;
    general: string;
    details: string;
    data: CookiesType[];
};

const CookieTab = ({ tab, general, details, data }: Props) => {
    const { t } = useTranslation('cookies');

    const columns = [
        {
            title: t('Name'),
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: t('Service'),
            key: 'service',
            dataIndex: 'service',
        },
        {
            title: t('Purpose'),
            key: 'purpose',
            dataIndex: 'purpose',
        },
        {
            title: t('Type and validity'),
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: t('Consent'),
            key: 'action',
            render: () => <Switch disabled defaultChecked />,
            width: '10%',
        },
    ];

    return (
        <>
            <Container>
                <h1>{t('Advanced settings')}</h1>
                <p className="main-description">{general}</p>
                <h2>{tab}</h2>
                <p>{details}</p>
            </Container>
            <SimpleTable dataSource={data} columns={columns} />
        </>
    );
};

export default CookieTab;
