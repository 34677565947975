import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Divider, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { Popover, container, DatePicker, button } from 'components/common';
import { AuditTypeCheckbox } from './AuditFilters';

const { PopoverRowContainer } = container;
const { PrettyButton, Button } = button;

const StyledClearBtn = styled(Button)`
    span {
        color: #f5222d !important;
    }
`;
const StyledCancelBtn = styled(Button)`
    span {
        color: #8c8c8c !important;
    }
`;
const StyledFilterBtn = styled(Button)`
    span {
        color: #1890ff !important;
    }
`;

type Props = {
    handleFilter: (date: [Dayjs, Dayjs] | null, result: string[]) => void;
    handleClear: () => void;
};

const AuditTableFilters: React.FC<Props> = ({ handleFilter, handleClear }: Props) => {
    const [date, setDate] = useState<[any, any] | null>(null);
    const [result, setResult] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);

    const [t, i18n] = useTranslation(['common', 'audit']);

    const clearFilters = () => {
        setDate(null);
        setResult([]);
    };

    const onCancel = () => {
        clearFilters();
        setVisible(false);
    };

    const onClear = () => {
        clearFilters();
        setVisible(false);
        handleClear();
    };

    const mapResultIntoQueryString = () => {
        const selectedTypes: string[] = [];
        result.forEach(type => {
            if (type.includes('%')) {
                const subtypes = type.split('%');
                subtypes.forEach(subtype => {
                    selectedTypes.push(subtype);
                });
            } else {
                selectedTypes.push(type);
            }
        });

        return selectedTypes;
    };

    const onFilter = () => {
        setVisible(false);
        handleFilter(date, mapResultIntoQueryString());
    };

    const handleSetResult = (types: string[]) => setResult(types);

    const content = (
        <PopoverRowContainer className="popover-content">
            <Row justify="space-between" style={{ width: '100%' }}>
                <Col xs={24} sm={6}>
                    <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c', marginRight: '40px' }}>
                        {t('common:date')}
                    </Typography.Text>
                </Col>
                <Col xs={24} sm={18}>
                    {i18n.language === 'pl' ? (
                        <DatePicker.RangePicker
                            value={date || undefined}
                            locale={locale}
                            onChange={dates => setDate(dates)}
                        />
                    ) : (
                        <DatePicker.RangePicker value={date || undefined} onChange={dates => setDate(dates)} />
                    )}
                </Col>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={24}>
                        <Typography.Text style={{ fontWeight: 'bold', color: '#8c8c8c' }}>
                            {t('common:result')}
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <AuditTypeCheckbox onSelect={handleSetResult} selected={result} />
                    </Col>
                </Row>
                <Divider style={{ marginTop: '10px', marginBottom: '0' }} />
                <Row justify="space-between" style={{ width: '100%' }}>
                    <Col span={14}>
                        <StyledClearBtn type="link" onClick={clearFilters}>
                            {t('common:clear_filters')}
                        </StyledClearBtn>
                    </Col>
                    <>
                        <StyledCancelBtn type="link" onClick={onCancel}>
                            {t('common:cancel')}
                        </StyledCancelBtn>
                        <StyledFilterBtn type="link" onClick={onFilter}>
                            {t('common:filter')}
                        </StyledFilterBtn>
                    </>
                </Row>
            </Row>
        </PopoverRowContainer>
    );

    return (
        <Row>
            <Popover
                content={content}
                className="filter-menu"
                trigger="click"
                visible={visible}
                onVisibleChange={setVisible}
                placement="bottom">
                <PrettyButton label={t('common:filter')} />
            </Popover>
            <Button type="link" onClick={onClear}>
                {t('common:clear')}
            </Button>
        </Row>
    );
};

export default AuditTableFilters;
