/* eslint-disable */
const isDicomFile = (file: UploadedFile) =>
    new Promise<UploadedFile>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (evt: any) => {
            if (evt.target.readyState === FileReader.DONE) {
                const array = new Uint8Array(evt.target.result);
                let s = '';
                for (let i = 128; i < 132; ++i) {
                    s += String.fromCharCode(array[i]);
                }

                if (s === 'DICM') {
                    file.isDicom = true;
                    return resolve(file);
                }
                return reject();
            }
        };
    });

export default isDicomFile;
