import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

const StyledSpace = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.background.white};
    border: 1px solid ${props => props.theme.background.pink};

    .ant-row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ant-col {
            padding: 10px;
        }
    }

    .ant-row:not(:first-child) {
        border-top: 1px solid ${props => props.theme.background.pink};
    }

    .ant-row {
        .ant-col:not(:last-child) {
            background: ${props => props.theme.background.pink};
            color: ${props => props.theme.text.white};
            border-right: 1px solid ${props => props.theme.background.pink};
        }
    }
`;

type Props = {
    columns: ITableColumn[];
    screeningInfo: IScreeningInfo;
};

const OneRowMobileTable = ({ screeningInfo, columns }: Props) => (
    <StyledSpace>
        {columns.map(col => (
            <Row key={col.key}>
                <Col>
                    <p style={{ margin: 0 }}>{col.title}: </p>
                </Col>
                <Col>
                    {col.render ? (
                        col.render(screeningInfo[col.dataIndex])
                    ) : (
                        <Space>
                            <p style={{ margin: 0 }}>{screeningInfo[col.dataIndex]}</p>
                        </Space>
                    )}
                </Col>
            </Row>
        ))}
    </StyledSpace>
);

export default OneRowMobileTable;
