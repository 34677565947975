import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

type Props = {
    guest: boolean;
    children?: React.ReactChild | React.ReactChild[];
};

const { Content: AntContent } = AntLayout;

const StyledContent = styled(AntContent)<{ guest: boolean }>`
    margin: ${props => (props.guest ? '0' : '0 18% 5% 18%')};
    @media screen and (max-width: 1000px) {
        margin: ${props => (props.guest ? '0' : '0 2% 5% 2%')};
    }
    background-color: ${props => props.theme.background.theme};
`;

const Layout: React.FC<Props> = ({ children, guest }: Props) => (
    <StyledContent guest={guest} className="content">
        {children}
    </StyledContent>
);

Layout.defaultProps = {
    children: <></>,
};

export default Layout;
