import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContactForm } from 'components/contact';
import styled from 'styled-components';
import { card } from 'components/common';
import { create } from 'dal/contact';
import { InterceptError, InterceptSuccess } from 'utils/interceptors';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';

const { LogoCard } = card;

const Container = styled.div`
    flex: 1;
    background-color: #b8c6db;
    ${props =>
        props.theme.background.theme
            ? `
            background-color: ${props.theme.background.theme};
            `
            : `
      background-color: #b8c6db;
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    `}
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo-card {
        padding-bottom: 2%;
    }
`;

const ContactView: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation('common');
    const onFinish = async (values: any) => {
        await create(values)
            .then(() => {
                InterceptSuccess(t('successfully_sent'));
                history.push(ROUTES.contact_submitted);
            })
            .catch(() => InterceptError(t('failed_sent')));
    };

    return (
        <Container className="container">
            <LogoCard>
                <ContactForm onFinish={onFinish} />
            </LogoCard>
        </Container>
    );
};

export default ContactView;
