import { getOrder } from 'dal/order';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Result, Button, Row, Spin, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ParamTypes {
    id: string | undefined;
}

const StyledResult = styled(Result)`
    svg {
        font-size: 48px;
    }
`;

const StyledRow = styled(Row)`
    width: 100vw !important;
    margin: 15 auto;
`;

const StyledSpin = styled(Spin)`
    font-size: 40px;
    margin: 10 auto;
    display: block;
    color: #8c8c8c;

    .anticon-spin.ant-spin-dot {
        font-size: 48px !important;
        color: #8c8c8c;
    }
`;

const OrderStatusView: React.FC = () => {
    const history = useHistory();
    const { id } = useParams<ParamTypes>();
    const { t } = useTranslation('payments');

    const [orderWithStatus, setOrderWithStatus] = useState<IOrderStatus | null>(null);

    const fetchOrderStatus = () => {
        if (id) getOrder(id).then(res => setOrderWithStatus(res.data));
    };

    useEffect(() => {
        fetchOrderStatus();
    }, []);

    if (orderWithStatus && orderWithStatus.status === 'COMPLETED')
        return (
            <StyledRow style={{ width: '100vw' }} justify="center">
                <StyledResult
                    status="success"
                    title={`${t('success_purchase')}: ${orderWithStatus.order.portalProduct.description}`}
                    subTitle={`${t('order_number')}: ${id}`}
                    extra={[
                        <Button onClick={() => history.push('/screenings')} type="primary" key="console">
                            {t('back')}
                        </Button>,
                    ]}
                />
            </StyledRow>
        );

    if (orderWithStatus && orderWithStatus.status === 'CANCELED')
        return (
            <StyledRow style={{ width: '100vw' }} justify="center">
                <StyledResult
                    status="error"
                    title={t('payment_cancel')}
                    subTitle={`${t('order_number')}: ${id}`}
                    extra={[
                        <Button onClick={() => history.push('/screenings')} type="primary" key="console">
                            {t('back')}
                        </Button>,
                    ]}
                />
            </StyledRow>
        );

    return (
        <div style={{ marginTop: '30vh' }}>
            <StyledRow justify="center">
                <StyledSpin size="large" tip={t('processing')} indicator={<LoadingOutlined />} />
            </StyledRow>
            <StyledRow justify="center">
                <Button type="primary" onClick={fetchOrderStatus}>
                    {t('refresh')}
                </Button>
            </StyledRow>
        </div>
    );
};

export default OrderStatusView;
