import React from 'react';
import { Divider, Row, Col } from 'antd';
import styled from 'styled-components';

type Props = {
    children?: React.ReactChild | React.ReactChild[] | any;
    text?: string;
    action?: React.ReactChild;
};

const Content = styled.div``;

const Container = styled.div`
    width: 100%;
    padding: 10px;
    background-color: ${props => props.theme.background.theme};
`;

const StyledDivider = styled(Divider)`
    border-top: 1px ${props => props.theme.text.white};
    margin: 24px 0 5px 0;
`;

const Text = styled.h3``;

const ExaminationContainer: React.FC<Props> = ({ children, text, action }: Props) => (
    <Container>
        <StyledDivider />
        <Row justify="space-between">
            <Col>
                <Text>{text}</Text>
            </Col>
            <Col>{action}</Col>
        </Row>
        <Content>{children}</Content>
    </Container>
);

ExaminationContainer.defaultProps = {
    children: <></>,
    action: <></>,
    text: '',
};

export default ExaminationContainer;
