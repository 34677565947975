import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ROUTES from 'utils/constants/routes.json';
import dayjs from 'dayjs';
import { Space } from 'components/common';
import Logo from '../assets/img/logo.png';

const { Text } = Typography;

const Container = styled.div`
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    h1 {
        color: #595959;
        font-size: 20px;
        text-align: center;
        margin-bottom: 25px;

        @media (min-width: 499px) {
            font-size: 30px;
        }

        @media (min-width: 1199px) {
            margin-bottom: 50px;
        }
    }

    .logo {
        display: block;
        margin: 0 auto;
    }
`;

const Tile = styled.div`
    box-sizing: border-box;
    padding: 40px;
    background-color: ${props => props.theme.background.white};
    width: 100%;
    height: 100%;

    -webkit-box-shadow: 0px 0px 3px 0px ${props => props.theme.background.revertTheme || '#d9d9d9'};
    -moz-box-shadow: 0px 0px 3px 0px ${props => props.theme.background.revertTheme || '#d9d9d9'};
    box-shadow: 0px 0px 5px 0px ${props => props.theme.background.revertTheme || '#d9d9d9'};
    transition: 0.3s ease-out;
    cursor: pointer;

    :hover {
        transform: scale(1.05);
    }

    .tie-header--text {
        font-size: 25px !important;
        color: ${props => props.theme.background.revertTheme || '#ffadd2'};
        text-align: center;

        @media (min-width: 799px) {
            font-size: 30px;
        }

        @media (min-width: 1199px) {
            font-size: 40px;
        }
    }
    .tie-body {
        color: ${props => props.theme.text.black} !important;
    }
`;

const H1 = styled.h1`
    color: ${props => props.theme.background.theme} !important;
    font-size: 25px !important;
`;

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    background: ${props => props.theme.background.revertTheme || '#fff0f6'};
    box-sizing: border-box;
`;

const LandingPage: React.FC<{}> = () => {
    const history = useHistory();
    const { t } = useTranslation('landingPage');
    return (
        <Wrapper className="container">
            <Container>
                <Row justify="center" style={{ width: '100%' }}>
                    <Col span={24}>
                        <img className="logo" src={Logo} alt="logo" />
                    </Col>
                    <Col span={24}>
                        <Space justifyContent="center" alignItems="center">
                            <Text type="secondary">{`Version ${process.env.REACT_APP_GIT_SHA} , ${dayjs(
                                process.env.REACT_APP_GIT_DATE
                            ).format('DD.MM.YYYY')}`}</Text>
                        </Space>
                        <H1 />
                    </Col>
                    <Col span={24}>
                        <H1>{t('where')}</H1>
                    </Col>
                </Row>
                <Row gutter={[0, 32]} justify="space-between" style={{ width: '100%' }}>
                    <Col xs={24} lg={11}>
                        <Tile onClick={() => history.push(ROUTES.login_result)}>
                            <div className="tie-header">
                                <p className="tie-header--text">{t('result_portal')}</p>
                            </div>
                            <div className="tie-body">{t('result_portal_body')}</div>
                        </Tile>
                    </Col>
                    <Col xs={24} lg={11}>
                        <Tile onClick={() => history.push(ROUTES.login_portal)}>
                            <div className="tie-header">
                                <p className="tie-header--text">{t('patient_portal')}</p>
                            </div>
                            <div className="tie-body">{t('patient_portal_body')}</div>
                        </Tile>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};

export default LandingPage;
