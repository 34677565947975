import React from 'react';
import { container } from 'components/common';
import { ChangePasswordCard, ProfileDetailsCard } from 'components/profileDetails';
import { useTranslation } from 'react-i18next';

const { ExaminationContainer } = container;

const ProfileDetailsView: React.FC = () => {
    const { t } = useTranslation('profileDetails');

    return (
        <>
            <ExaminationContainer text={t('my_data_title')}>
                <ProfileDetailsCard />
            </ExaminationContainer>
            <ExaminationContainer text={t('change_password')}>
                <ChangePasswordCard />
            </ExaminationContainer>
        </>
    );
};

export default ProfileDetailsView;
