import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as dal from 'dal';
import { ISurveyResponse } from 'interfaces/SurveyResponse';
import SurveyQuestion from 'interfaces/SurveyQuestion';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';
import styled from 'styled-components';
import { AccessibilitySection } from 'components/features/accessibility';
import { card, Space } from '../components/common';
import { SurveyHeader, Survey, useQuestion } from '../components/survey';
import CustomSpin from '../components/common/CustomSpin';

const { LogoCard } = card;

const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background.theme};
    flex-direction: column;
    max-width: 100vw;
    overflow-x: hidden;

    .ant-typography,
    .ant-radio-wrapper,
    h3,
    h5 {
        color: ${props => props.theme.text.black} !important;
    }
`;

interface ParamTypes {
    id: string | undefined;
}

const SurveyView: React.FC = () => {
    const history = useHistory();
    const { id } = useParams<ParamTypes>();

    const { questions } = useQuestion();
    const [surveyQuestions, setSurveyQuestions] = useState<SurveyQuestion[]>([...questions]);
    const [mode, setMode] = useState<'new' | 'view'>('new');

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation('surveys');

    const mapResponseIntoQuestions = (response: ISurveyResponse) => {
        const keyToKeyMapper = [
            {
                name: 'screeningAccess',
                key: 1,
            },
            {
                name: 'qualityOfService',
                key: 2,
            },
            {
                name: 'informationAccess',
                key: 3,
            },
            {
                name: 'patientComfort',
                key: 4,
            },
            {
                name: 'staffBehaviour',
                key: 5,
            },
            {
                name: 'painMeasure',
                key: 6,
            },
            {
                name: 'guardQuestion',
                key: 7,
            },
            {
                name: 'longTermPain',
                key: 8,
            },
        ];

        Object.entries(response).forEach(res => {
            const [key, value] = res;
            const param = keyToKeyMapper.find(obj => obj.name === key);
            if (!param) return;

            const question = surveyQuestions.find(q => q.key === param.key);

            if (question) question.answerValue = value;

            if (key === 'longTermPain') {
                const guard = surveyQuestions.find(q => q.key === 7);
                if (!guard) return;
                guard.answerValue = value ? 1 : 0;
            }
        });
    };

    useEffect(() => {
        const consumeResponse = (res: any) => {
            if (res.status === 204 && !res.data) {
                setMode('new');
            }

            if (res.data) {
                setMode('view');
                mapResponseIntoQuestions(res.data);
            }

            setSurveyQuestions([...questions]);
        };

        const getSurvey = () => {
            setLoading(true);
            dal.surveys
                .get()
                .then(res => consumeResponse(res))
                .catch(err => {})
                .finally(() => setLoading(false));
        };

        const getSurveyById = (surveyId: number) => {
            setLoading(true);
            dal.surveys
                .getById(surveyId)
                .then(res => consumeResponse(res))
                .catch(err => {})
                .finally(() => setLoading(false));
        };

        if (id) {
            getSurveyById(+id);
        } else {
            getSurvey();
        }
    }, []);

    const clearOutData = (surveyData: SurveyQuestion[]): (number | null)[] =>
        surveyData
            .filter(question => question.accessDefinerOf === undefined && question.scaleType === undefined)
            .sort((a, b) => a.key - b.key)
            .map(q => q.answerValue);

    const mapAnswersToDatabaseColumns = (answers: (number | null)[]) => {
        const columnMapper = {
            screeningAccess: null,
            qualityOfService: null,
            informationAccess: null,
            patientComfort: null,
            staffBehaviour: null,
            painMeasure: null,
            longTermPain: null,
        };

        const arrayOfEntries: any = [];
        Object.keys(columnMapper).forEach((key, index) => {
            arrayOfEntries.push([key, answers[index]]);
        });

        return Object.fromEntries(new Map([...arrayOfEntries]));
    };

    const onSurveySubmit = () => {
        const clearedQuestions = clearOutData(surveyQuestions);
        const requestBody = mapAnswersToDatabaseColumns(clearedQuestions);
        if (id) {
            dal.surveys
                .addById(+id, requestBody)
                .then(() => history.push(ROUTES.mammography_screenings))
                .catch(() => {});
        } else {
            dal.surveys
                .add(requestBody)
                .then(() => history.push(ROUTES.screening))
                .catch(() => {});
        }
    };

    const onCancel = () => {
        if (id) return history.push(ROUTES.mammography_screenings);

        return history.push(ROUTES.screening);
    };

    const onSurveyQuestionUpdate = (key: number, value: number | null) => {
        const searchedQuestion = surveyQuestions.find(q => q.key === key);
        if (!searchedQuestion) return;

        searchedQuestion.answerValue = value;
        const newSurveyState = surveyQuestions.filter(q => q.key !== key);
        setSurveyQuestions([...newSurveyState, searchedQuestion].sort((a, b) => a.key - b.key));
    };

    if (loading) return <CustomSpin containerStyles={{ width: '100%', height: '100vh' }} />;

    return (
        <Container className="container">
            <Space direction="column">
                <LogoCard>
                    <SurveyHeader title={t('survey_header_title')} description={t('survey_header_description')} />
                </LogoCard>
                <Survey
                    mode={mode}
                    surveyQuestions={surveyQuestions}
                    onSubmit={onSurveySubmit}
                    onCancel={onCancel}
                    onSurveyQuestionUpdate={onSurveyQuestionUpdate}
                />
            </Space>
        </Container>
    );
};

export default SurveyView;
