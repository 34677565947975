import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    LoginView,
    ScreeningDetailsView,
    MammographyScreeningsView,
    SurveyView,
    HelpView,
    ContactView,
    ProfileDetailsView,
    LandingPage,
    ReferralView,
    ResetPasswordView,
    ChangePasswordView,
    ConsentRegulationView,
    PortalRegulationView,
    ContactSubmittedView,
    OrderStatusView,
    PasswordLinkSentView,
    BuyConsultationView,
} from 'views';
import { Layout } from 'components/layout';
import { useLocalStorage, useAuthorization } from 'hooks';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';
import { ThemeProvider } from 'styled-components';
import { Theme, GlobalStyles } from 'components/themes';
import { accessibility } from 'components/features';
import UploadDicomsView from 'views/UploadDicomsView';
import AuditView from 'views/AuditView';

const { accessibilityThemes } = Theme;
const { AccessibilitySection } = accessibility;

const anonymousPaths = [
    ROUTES.login_portal,
    ROUTES.faq,
    ROUTES.contact,
    ROUTES.login_result,
    ROUTES.base,
    ROUTES.reset_password,
    ROUTES.change_password_with_token,
    ROUTES.portal_consent,
    ROUTES.portal_regulations,
    ROUTES.contact_submitted,
    ROUTES.reset_password_link_sent,
    ROUTES.password_changed,
];

const App: React.FC = () => {
    const { i18n } = useTranslation();
    const [storedValue] = useLocalStorage('contrast', 'default');
    const [lang] = useLocalStorage('lang', 'pl');
    const { t } = useTranslation(['common', 'passwordManagement']);
    const { user, isAuthenticated, isAnonymousPathAllowed, isScreeningsAuthorized } = useAuthorization({
        anonymousPaths,
    });

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, []);

    return (
        <ThemeProvider theme={accessibilityThemes[storedValue as Theme]}>
            <GlobalStyles />
            <AccessibilitySection />
            <Switch>
                <Route exact path={ROUTES.base} component={LandingPage} />
                <Route exact path={ROUTES.login_result} render={() => <LoginView loginType="resultPortal" />} />
                <Route exact path={ROUTES.login_portal} render={() => <LoginView loginType="patientPortal" />} />
                <Route path={ROUTES.faq} component={HelpView} />
                <Route exact path={ROUTES.change_password_with_token} component={ChangePasswordView} />
                <Route exact path={ROUTES.reset_password} component={ResetPasswordView} />
                <Route exact path={ROUTES.contact} component={ContactView} />
                <Route exact path={ROUTES.survey} component={SurveyView} />
                <Route path={ROUTES.survey_details} component={SurveyView} />
                <Route exact path={ROUTES.portal_consent} component={ConsentRegulationView} />
                <Route exact path={ROUTES.portal_regulations} component={PortalRegulationView} />
                <Route exact path={ROUTES.contact_submitted} component={ContactSubmittedView} />
                <Route exact path={ROUTES.order_status} component={OrderStatusView} />
                <Route
                    exact
                    path={ROUTES.reset_password_link_sent}
                    render={() => (
                        <PasswordLinkSentView
                            mainText={t('passwordManagement:email_sent')}
                            linkText={t('common:return')}
                        />
                    )}
                />
                <Route
                    exact
                    path={ROUTES.password_changed}
                    render={() => (
                        <PasswordLinkSentView
                            mainText={t('passwordManagement:password_changed')}
                            linkText={t('common:return')}
                        />
                    )}
                />
                <Layout guest={isAuthenticated && !user.id}>
                    <Route exact path={ROUTES.screening} component={ScreeningDetailsView} />
                    <Route exact path={ROUTES.portal_audit} component={AuditView} />
                    <Route exact path={ROUTES.audit} component={AuditView} />
                    <Route path={ROUTES.referral} component={ReferralView} />
                    <Route exact path={ROUTES.mammography_screenings} component={MammographyScreeningsView} />
                    <Route exact path={ROUTES.profile_portal} component={ProfileDetailsView} />
                    <Route exact path={ROUTES.upload} component={UploadDicomsView} />
                    <Route exact path={ROUTES.buy_consultation} component={BuyConsultationView} />
                </Layout>
                <Route path={ROUTES.base} component={LandingPage} />
                {isAuthenticated && user.id && <Redirect to={ROUTES.mammography_screenings} />}
                {isAuthenticated && !user.id && <Redirect to={ROUTES.screening} />}
            </Switch>
        </ThemeProvider>
    );
};

export const layoutWrappedPaths = [
    ROUTES.screening,
    ROUTES.portal_audit,
    ROUTES.audit,
    ROUTES.referral,
    ROUTES.mammography_screenings,
    ROUTES.profile_portal,
    ROUTES.upload,
    ROUTES.buy_consultation,
];

export default App;
