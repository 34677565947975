import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { card, text, Space } from 'components/common';
import { Container } from 'components/common/container/InformationContainer';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';

const { LogoCard } = card;
const { Title } = text;

const HelpView: React.FC = () => {
    const { t } = useTranslation(['faq', 'common']);
    return (
        <Container className="container">
            <LogoCard shadow maxWidth="90%">
                <Space direction="column">
                    <Title>{t('faq:title')}</Title>
                    <Title level={2}>{t('faq:subtitle_1')}</Title>
                    <Title level={3}>{t('faq:content_1_1')}</Title>
                    <Title level={3}>{t('faq:content_1_2')}</Title>
                    <Title level={3}>{t('faq:content_1_3')}</Title>
                    <Title level={3}>{t('faq:content_1_4')}</Title>
                    <Title level={2}>{t('faq:subtitle_2')}</Title>
                    <Title level={3}>{t('faq:content_2_1')}</Title>
                    <Title level={3}>{t('faq:content_2_2')}</Title>
                </Space>
            </LogoCard>
            <Space direction="column">
                <p style={{ textAlign: 'center', margin: 0 }}>{t('faq:footer')}</p>
                <Link to={ROUTES.contact}>
                    <Button type="link"> {t('faq:footer_button')}</Button>
                </Link>
            </Space>
            <Link to={ROUTES.base}>
                <Button style={{ marginTop: '10px' }} type="link">
                    <Button type="link">{t('common:return')}</Button>
                </Button>
            </Link>
        </Container>
    );
};

export default HelpView;
