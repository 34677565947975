import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { card, Space, container, SimpleTable, OneRowMobileTable } from 'components/common';
import { SingleScreeningView } from 'components/portalScreening';
import { InterceptError, InterceptSuccess } from 'utils/interceptors';
import { useTranslation } from 'react-i18next';
import * as dal from 'dal';
import { download } from 'utils/helpers/download';
import dayjs from 'dayjs';
import i18n from 'i18n';
import CustomSpin from 'components/common/CustomSpin';
import { useWindowWidth } from 'hooks';

type BannerProps = {
    position: string;
};

type Props = {};

const { LogoCard } = card;
const { TextContainer } = container;

const Container = styled.div`
    flex: 1;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #b8c6db;
    background: ${(props: { img: string | undefined; theme: any }) =>
        props.img
            ? `url(${props.img})`
            : `${props.theme.background.theme || 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)'}`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
`;

const ScreeningDetailsView: React.FC<Props> = () => {
    const [background, setBackground] = useState<IBanner | undefined>(undefined);
    const [banners, setBanners] = useState<IBanner[]>([]);
    const [screeningInfo, setScreeningInfo] = useState<IScreeningInfo | undefined>(undefined);
    const [screeningResults, setScreeningResults] = useState<IScreeningResult[]>([]);
    const [hasSurvey, setHasSurvey] = useState(false);
    const [isoStatus, setIsoStatus] = useState<IsoStatus>('');
    const [loadingState, setLoadingState] = useState<LoadingState>('global');
    const [portalProducts, setPortalProducts] = useState<IPortalProduct[]>([]);

    const { isMobile } = useWindowWidth();

    const { t } = useTranslation(['examinationDetails', 'notifications']);

    useEffect(() => {
        const fetchBackground = () => dal.banners.get('banner-background').then(res => setBackground(res.data[0]));
        const fetchLogoBanners = () => dal.banners.get('banner-logo').then(res => setBanners(res.data));

        const fetchScreeningInfo = () =>
            dal.screenings
                .getScreeningInfo()
                .then((res: any) => setScreeningInfo(res.data))
                .catch(() => setScreeningInfo(undefined));

        const fetchResults = () =>
            dal.screenings
                .getScreeningResults()
                .then(res => {
                    if (res.data.success === false) throw new Error();
                    const newScreeningResults = res.data.documents.map((result: any) => {
                        const screeningResult = {
                            id: result.id,
                            name: result.name,
                            type: result.type,
                        };
                        return screeningResult;
                    });
                    setScreeningResults(newScreeningResults);
                })
                .catch(() => setScreeningResults([]));

        const fetchSurvey = () =>
            dal.surveys
                .get()
                .then(res => {
                    if (res.status === 204 && !res.data) {
                        setHasSurvey(false);
                    }

                    if (res.data) {
                        setHasSurvey(true);
                    }
                })
                .catch(() => setHasSurvey(false));

        const checkIsIsoReady = () =>
            dal.iso
                .check()
                .then(res => setIsoStatus(res.data))
                .catch(() => setIsoStatus(''));

        const fetchProducts = () => {
            dal.products
                .getProducts()
                .then(res => setPortalProducts(res.data))
                .catch(() => setPortalProducts([]));
        };

        setLoadingState('global');
        Promise.allSettled([
            fetchBackground(),
            fetchLogoBanners(),
            fetchScreeningInfo(),
            fetchResults(),
            fetchSurvey(),
            checkIsIsoReady(),
            fetchProducts(),
        ]).finally(() => setLoadingState(''));
    }, []);

    useEffect(() => {
        const fetchResult = (document: IScreeningResult) => {
            if (screeningInfo) {
                return dal.screenings
                    .downloadScreeningResultById(screeningInfo.id, document.id)
                    .then(res => {
                        // zawsze istnieje bo inaczej catch
                        document.file = res.data;
                    })
                    .catch(err => {
                        document.file = null;
                    })
                    .finally(() => {
                        const updatedScreeningResults = [
                            ...screeningResults.filter(screningResult => screningResult.id !== document.id),
                            document,
                        ];
                        setScreeningResults(updatedScreeningResults);
                    });
            }

            return dal.screenings
                .downloadScreeningResult(document.id)
                .then(res => {
                    // zawsze istnieje bo inaczej catch
                    document.file = res.data;
                })
                .catch(err => {
                    document.file = null;
                })
                .finally(() => {
                    const updatedScreeningResults = [
                        ...screeningResults.filter(screningResult => screningResult.id !== document.id),
                        document,
                    ];
                    setScreeningResults(updatedScreeningResults);
                });
        };

        if (screeningResults.some(r => r.file === undefined)) {
            setLoadingState('screeningResult');
            const promises = [...screeningResults.filter(r => r.file === undefined).map(r => fetchResult(r))];
            Promise.allSettled(promises).finally(() => setLoadingState(''));
        }
    }, [screeningResults]);

    const fetchInstruction = (type: 'iso' | 'referral-information') => {
        setLoadingState('isoInstruction');
        const fileName =
            type === 'referral-information'
                ? 'Informacja o skierowaniu.pdf'
                : 'Instrukcja postępowania z obrazem ISO.pdf';

        dal.banners
            .getInstruction(type)
            .then(res => download(res.data, fileName))
            .finally(() => setLoadingState(''));
    };

    const fetchIso = () => {
        setLoadingState('iso');
        dal.iso
            .get()
            .then(res => download(res.data, 'ISO.iso'))
            .catch(err => {
                if (err.status === 503 && err.detail === 'no_connection')
                    return InterceptError(t('notifications:no_connection'));
                return InterceptError(t('examinationDetails:iso_download_fail'), '');
            })
            .finally(() => setLoadingState(''));
    };

    const generateIso = () => {
        setLoadingState('iso');
        dal.iso
            .generate()
            .then(() => {
                InterceptSuccess(t('examinationDetails:iso_request_accepted'));
                setIsoStatus('new');
            })
            .catch(err => {
                if (
                    err?.response?.data &&
                    err.response.data.status === 503 &&
                    err.response.data.detail === 'no_connection'
                )
                    return InterceptError(t('notifications:no_connection'));
                if (
                    err?.response?.data &&
                    err.response.data.status === 500 &&
                    err.response.data.detail === 'iso_generator_full_status'
                )
                    return InterceptError(t('notifications:iso_generator_full'));
                return InterceptError(t('examinationDetails:iso_generate_fail'));
            })
            .finally(() => setLoadingState(''));
    };

    const columns: ITableColumn[] = [
        {
            title: t('examinationDetails:examination'),
            dataIndex: 'createdAt',
            key: 'created',
            render: (timestamp: string) => {
                const createdAt = dayjs(timestamp).locale(i18n.language);
                return (
                    <Space direction="row" style={{ gap: '2px', flexWrap: 'wrap' }}>
                        <span>{createdAt.format('DD MMMM YYYY')}</span>
                        <p style={{ marginBottom: 0 }}>{createdAt.format('HH:mm')}</p>
                    </Space>
                );
            },
        },
        {
            title: t('examinationDetails:first_name'),
            dataIndex: 'personFirstName',
            key: 'firstName',
        },
        {
            title: t('examinationDetails:last_name'),
            dataIndex: 'personLastName',
            key: 'lastName',
        },
    ];

    // TODO: Stworzyć jako nowy komponent

    const Banner = ({ position }: BannerProps) => {
        const banner = banners.find(b => b.position === position);
        if (!banner) return <></>;
        return (
            <>
                <TextContainer textAlign="center">
                    <img src={banner.url} alt="Sponsor logo #top" width="200" />
                </TextContainer>
            </>
        );
    };

    if (loadingState === 'global') return <CustomSpin containerStyles={{ width: '100%', height: '100vh' }} />;

    const getScreeningInfo = () => {
        if (!screeningInfo) {
            return <></>;
        }

        if (isMobile) {
            return <OneRowMobileTable columns={columns} screeningInfo={screeningInfo} />;
        }

        return <SimpleTable columns={columns} dataSource={[screeningInfo]} />;
    };

    return (
        <Container className="screening-container container" img={background?.url}>
            <>
                <Banner position="top-placement" />
                <LogoCard maxWidth="900px" background="#f5f5f5">
                    <Space direction="column" alignItems="flex-start" justifyContent="flex-start">
                        {getScreeningInfo()}
                        <SingleScreeningView
                            loadingState={loadingState}
                            type="resultPortal"
                            fetchResult={download}
                            fetchIsoInstruction={() => fetchInstruction('iso')}
                            fetchReferralInformation={() => fetchInstruction('referral-information')}
                            resultsPdfs={screeningResults}
                            portalProducts={portalProducts}
                            hasSurvey={hasSurvey}
                            isoStatus={isoStatus}
                            fetchIso={fetchIso}
                            generateIso={generateIso}
                        />
                    </Space>
                </LogoCard>
                <Banner position="bottom-placement" />
            </>
        </Container>
    );
};

export default ScreeningDetailsView;
