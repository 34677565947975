import React from 'react';
import { Form, FormItemProps } from 'antd';
import styled from 'styled-components';

type Props = {
    justifyContent?: JustifyContent;
} & FormItemProps;

const StyledItem = styled<any>(Form.Item)`
    display: flex;
    max-width: 100%;
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: ${(props: Props) => props.justifyContent};
    }
    @media screen and (max-width: 450px) {
        padding-left: 2%;
        padding-right: 2%;
    }
    div {
        width: 100%;
    }
`;

const Item: React.FC<Props> = (props: Props) => {
    const { children } = props;
    return (
        <StyledItem className="item" {...props}>
            {children}
        </StyledItem>
    );
};

Item.defaultProps = {
    justifyContent: 'inherit',
};

export default Item;
