import { notification } from 'antd';

type Props = {
    message: string;
    description?: string;
    type: 'success' | 'error' | 'info' | 'warn';
};

const openNotificationWithIcon = ({ type, message, description = '' }: Props) => {
    notification[type]({
        message,
        duration: 10,
        description,
    });
};

export { openNotificationWithIcon };
