import React from 'react';
import styled from 'styled-components';
import { Form, Input, Checkbox } from 'antd';
import { button, form as commonForm } from 'components/common';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { isPhoneNumberPolish, isPossiblyPhoneNumber } from 'utils/helpers/phoneValidation';

type Props = {
    onFinish: (values: any) => void;
};

const { DefaultButton } = button;
const { Item } = commonForm;

const StyledForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const ContactForm: React.FC<Props> = ({ onFinish }: Props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('contactForm');

    const handleFinish = (values: any) => {
        form.resetFields();
        onFinish(values);
    };

    return (
        <StyledForm form={form} name="contact-form" initialValues={{ remember: true }} onFinish={handleFinish}>
            <Form.Item label="" name="firstName" rules={[{ required: true, message: '' }]}>
                <Input placeholder={t('firstName')} />
            </Form.Item>
            <Form.Item label="" name="lastName" rules={[{ required: true, message: '' }]}>
                <Input placeholder={t('lastName')} />
            </Form.Item>
            <Form.Item name="email" label="" rules={[{ type: 'email', message: '' }]}>
                <Input placeholder={t('email')} />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                rules={[
                    {
                        required: true,
                        type: 'string',
                        message: '',
                        validator: (_, value) =>
                            value && isPossiblyPhoneNumber(value.split(' ').join(''))
                                ? Promise.resolve()
                                : Promise.reject(),
                    },
                    {
                        required: false,
                        type: 'string',
                        warningOnly: true,
                        message: t('not_polish_number'),
                        validator: (_, value) => {
                            const mapped = value.split(' ').join('');

                            return mapped && isPossiblyPhoneNumber(mapped) && isPhoneNumberPolish(mapped)
                                ? Promise.resolve()
                                : Promise.reject();
                        },
                    },
                ]}>
                <Input type="string" placeholder={t('phone')} />
            </Form.Item>
            <Form.Item name="content" label="" rules={[{ required: true, type: 'string', message: '' }]}>
                <Input.TextArea maxLength={300} rows={4} placeholder={t('content')} />
            </Form.Item>
            <Form.Item
                name="consent"
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error(t('consent_fail'))),
                    },
                ]}>
                <Checkbox>{t('consent')}</Checkbox>
            </Form.Item>
            {/* commented out, because CAPTCHA shows that sitekey is incorrect, we should turn it on 
            when we will have sitekey */}
            {/* <Form.Item name="captcha" noStyle rules={[{ required: true, message: '' }]}>
                <ReCAPTCHA sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY || '' } />
            </Form.Item> */}
            <Item justifyContent="center" style={{ marginTop: '4%' }}>
                <DefaultButton customWidth="50%" htmlType="submit">
                    {t('submit')}
                </DefaultButton>
            </Item>
        </StyledForm>
    );
};

export default ContactForm;
