import { api } from '../api';

export const newOrder = (productId: string, documentId: number, screeningId = -1) =>
    api.post('/order', { screeningId, productId, documentId });

export const newConsultationOrder = (productId: string, screeningId = -1, consultationRegistrationId: number, userId: number) =>
    api.post('/order-consultation', { screeningId, productId, consultationRegistrationId, userId });

export const makePayment = (orderId: string, consultationRegistrationId: number | null) => api.post('/payment', { orderId, consultationRegistrationId });

export const getOrder = (orderId: string) => api.get(`/order/${orderId}/status`);
