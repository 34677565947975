import React from 'react';
import styled from 'styled-components';

type Props = {
    children?: React.ReactChild | React.ReactChild[];
    justifyContent?: JustifyContent;
    alignItems?: AlignItems;
    direction?: FlexDirection;
    style?: any;
};

const CustomSpace = styled.div<Props>`
    width: 100%;
    display: flex;
    flex-direction: ${(props: Props) => props.direction};
    align-items: ${(props: Props) => props.alignItems};
    justify-content: ${(props: Props) => props.justifyContent};
    color: ${props => props.theme.text.black};
`;

const Space: React.FC<Props> = ({ children, justifyContent, direction, alignItems, style }: Props) => (
    <CustomSpace
        className="space"
        style={style}
        justifyContent={justifyContent}
        alignItems={alignItems}
        direction={direction}>
        {children}
    </CustomSpace>
);

Space.defaultProps = {
    children: <></>,
    alignItems: 'inherit',
    direction: 'inherit',
    justifyContent: 'inherit',
};

export default Space;
