import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { card, text, Space } from 'components/common';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';
import { Button } from 'antd';

const { LogoCard } = card;
const { Title } = text;

const Container = styled.div`
    flex: 1;
    background-color: #b8c6db;
    ${props =>
        props.theme.background.theme
            ? `
            background-color: ${props.theme.background.theme};
            `
            : `
      background-color: #b8c6db;
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    `}
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo-card {
        padding-bottom: 2%;
    }

    .space {
        padding: 1%;
        > h2,
        > h3 {
            color: ${props => props.theme.background.revertTheme || '#ffadd2'};
        }
        button {
            margin-top: 10px;
            &:hover {
                .ant-btn {
                    border-bottom: 1px solid '#ffadd2';
                }
            }
            width: fit-content;
        }
    }
`;

const ContactSubmittedView: React.FC = () => {
    const { t } = useTranslation(['common']);
    return (
        <Container className="container">
            <LogoCard>
                <Space alignItems="center" direction="column">
                    <Title level={2}>{t('common:contact_success')}</Title>
                    <Link to={ROUTES.base}>
                        <Button type="link" key="console">
                            <Button type="link">{t('common:return')}</Button>
                        </Button>
                    </Link>
                </Space>
            </LogoCard>
        </Container>
    );
};

export default ContactSubmittedView;
