import { Modal, Row, Col, Input, Button } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PAYU_LOGO_LIME from '../../assets/img/PAYU_LOGO_LIME.png';

type Props = {
    product: IPortalProduct;
    visible: boolean;
    handlePayment: (product: IPortalProduct) => void;
    handleCancel: () => void;
    loading: boolean;
    personalData: IactPersonalData | undefined;
    email: string;
    descriptionPurchase?: string;
};

const StyledModal = styled(Modal)`
    width: 80vw !important;
    padding: 50px;
    max-width: 1000px;

    .description {
        font-size: 1.1em !important;
        margin-bottom: 25px;
        display: block;
    }

    .description.price-description {
        font-size: 1.2em !important;
        font-weight: bold;
    }
    img {
        width: 100px;
        height: 50px;
    }

    .back-btn {
        font-size: 1.1em;
        color: #595959;
        transition: 0.3s;
    }

    .back-btn:hover {
        color: #f5222d;
    }

    .payment-btn {
        font-size: 1.2em;
        font-weight: bold;
    }
`;

const StyledTitle = styled.h2`
    color: #595959;
`;

const StyledInputWithLabel = styled.div`
    input {
        margin-bottom: 10px;
    }

    p {
        color: #595959;
        font-size: 1.1em !important;
        font-weight: bold;
    }
`;

const PayUCard = styled.div`
    width: 100%;
    img {
        width: 100%;
        max-width: 200px;
        height: auto;
    }
`;

const CheckoutModal: React.FC<Props> = ({
    product,
    visible,
    handlePayment,
    handleCancel,
    loading,
    personalData,
    email,
    descriptionPurchase
}) => {
    const { t } = useTranslation('payments');

    return (
        <StyledModal
            visible={visible}
            title={descriptionPurchase || t('description_purchase')}
            onCancel={handleCancel}
            footer={[
                <Button className="back-btn" key="back" type="link" onClick={handleCancel}>
                    {t('cancel')}
                </Button>,
                <Button className="payment-btn" key="submit" loading={loading} onClick={() => handlePayment(product)}>
                    {t('pay')} ({product.unitPrice / 100}) PayU
                </Button>,
            ]}>
            <Row>
                <Col span={10}>
                    <StyledTitle>{t('product')}</StyledTitle>
                    <span className="description">{product.description}</span>
                    <StyledTitle>{t('price')}</StyledTitle>
                    <span className="description price-description">{product.unitPrice / 100} zł</span>
                </Col>

                {personalData && (
                    <Col offset={2} span={12}>
                        <Row style={{ width: '80%' }} justify="space-between">
                            <StyledInputWithLabel>
                                <p>{t('name')}</p>
                                <Input type="text" value={personalData.firstName} disabled />
                            </StyledInputWithLabel>
                            <StyledInputWithLabel>
                                <p>{t('last_name')}</p>
                                <Input type="text" value={personalData.lastName} disabled />
                            </StyledInputWithLabel>
                        </Row>
                        <Col>
                            <StyledInputWithLabel>
                                <p>{t('email')}</p>
                                <Input type="email" value={email} disabled />
                            </StyledInputWithLabel>
                        </Col>
                    </Col>
                )}
                <PayUCard>
                    <Row>
                        <Col span={12}>
                            <StyledTitle>{t('pay_u_desc_title')}</StyledTitle>
                            <p>{t('pay_u_desc')}</p>
                        </Col>
                        <Col span={12}>
                            <img src={PAYU_LOGO_LIME} alt="PayU logo" />
                        </Col>
                    </Row>
                </PayUCard>
            </Row>
        </StyledModal>
    );
};

export default CheckoutModal;
