import { useState } from 'react';
import SurveyQuestion from 'interfaces/SurveyQuestion';
import { Button, Typography, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCard } from './index';
import NextQuestionGuard from './NextQuestionGuard';

type SurveyProps = {
    mode: 'new' | 'view';
    surveyQuestions: SurveyQuestion[];
    onSubmit: () => void;
    onCancel: () => void;
    onSurveyQuestionUpdate: (key: number, value: number | null) => void;
};

const Survey = ({ mode, surveyQuestions, onSubmit, onSurveyQuestionUpdate, onCancel }: SurveyProps) => {
    const [guardValue, setGuardValue] = useState(0);
    const { t } = useTranslation('surveys');
    const handleQuestionGuardChange = (key: number, value: number, isDefinerOf: number) => {
        onSurveyQuestionUpdate(key, value);
        onSurveyQuestionUpdate(isDefinerOf, null);
        setGuardValue(value);
    };

    const surveyMapper = (question: SurveyQuestion) => {
        switch (mode) {
            case 'new':
                if (question.accessDefinerOf)
                    return (
                        <NextQuestionGuard
                            definderOf={question.accessDefinerOf}
                            index={question.key}
                            question={question.question}
                            handleChange={handleQuestionGuardChange}
                            disabled={false}
                            value={guardValue}
                        />
                    );

                if (question.definedBy) {
                    const definer = surveyQuestions.find(q => q.key === question.definedBy);
                    return (
                        <QuestionCard
                            index={question.key}
                            question={question.question}
                            handleChange={onSurveyQuestionUpdate}
                            value={question.answerValue || 0}
                            disabled={definer && definer.answerValue === 0}
                            reversed={question.reversed}
                        />
                    );
                }

                return (
                    <QuestionCard
                        index={question.key}
                        question={question.question}
                        handleChange={onSurveyQuestionUpdate}
                        value={question.answerValue || 0}
                        reversed={question.reversed}
                    />
                );

            case 'view':
                if (question.accessDefinerOf) {
                    const definingValue = surveyQuestions.find(q => q.key === question.accessDefinerOf)?.answerValue;
                    return (
                        <NextQuestionGuard
                            definderOf={question.accessDefinerOf}
                            index={question.key}
                            question={question.question}
                            handleChange={() => {}}
                            value={definingValue ? 1 : 0}
                            disabled
                        />
                    );
                }

                return (
                    <QuestionCard
                        index={question.key}
                        question={question.question}
                        handleChange={() => {}}
                        value={question.answerValue || undefined}
                        reversed={question.reversed}
                        disabled
                    />
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            {surveyQuestions.map(surveyMapper)}
            {mode === 'view' && (
                <Row style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                        className="accessibility-btn"
                        onClick={onCancel}
                        style={{ marginTop: '25px', padding: 10, height: 'auto' }}>
                        {t('go_back')}
                    </Button>
                </Row>
            )}
            {mode === 'new' && (
                <>
                    <Typography.Paragraph style={{ padding: 5 }}>{t('thank_you')}</Typography.Paragraph>
                    <Row justify="center" style={{ width: '100%', margin: '0 auto' }}>
                        <Button
                            className="accessibility-btn"
                            onClick={() => onSubmit()}
                            type="primary"
                            style={{ marginTop: '25px', height: 'auto', marginRight: '25px' }}>
                            {t('send_survey')}
                        </Button>
                        <Button
                            className="accessibility-btn"
                            onClick={onCancel}
                            style={{ marginTop: '25px', height: 'auto' }}>
                            {t('cancel')}
                        </Button>
                    </Row>
                </>
            )}
        </>
    );
};

export default Survey;
