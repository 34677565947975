import { Select, SelectProps } from 'antd';
import React from 'react';
import styled from 'styled-components';


interface IProps extends SelectProps<any> {}

const StyledSelect = styled(Select)<IProps>`
    font-size: 1rem;
    line-height: 1rem;

    &, &:hover, &:active, &:focus {
        color: ${props => props.theme.text.selected} !important;
        border-color: ${props => props.theme.text};
    }
    
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${props => props.theme.background.body };
        border-color: ${props => props.theme.background.border || "#d9d9d9"} !important;
    }

    .ant-select-arrow, .ant-select-clear {
        color: ${props => props.theme.text.selected};
        background-color: ${props => props.theme.background.body};
    }

    ::placeholder {
        color: ${props => props.theme.text.black};
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: ${props => props.theme.text.black};
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.text.black};
    }
`;

const CustomSelect: React.FC<IProps> = props => (
    <StyledSelect dropdownClassName="custom-select-list" allowClear {...props}>
        {props.children}
    </StyledSelect>
);

export default CustomSelect;
