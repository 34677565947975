import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Logo from 'assets/img/logo.png';
import { button } from 'components/common';
import { useTranslation } from 'react-i18next';
import { AccessibilitySection } from 'components/features/accessibility';
import ROUTES from 'utils/constants/routes.json';
import DropdownMenu from './DropdownMenu';

const { LinkButton } = button;
const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
    display: flex;
    flex-wrap: wrap;
    background-color: ${props => props.theme.background.white};
    min-height: 90px;
    height: auto;
    flex-direction: column;
    padding: 0;
    align-items: center;
`;

const LogoSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;

    .logo-img {
        margin-top: 25px;
        max-width: 500px;
    }

    @media (max-width: 800px) {
        .logo-img {
            max-width: 300px;
        }
    }

    @media (min-width: 1320px) {
        min-height: 50px;
        .logo-img {
            margin-top: 0;
        }
    }
`;

const MenuSection = styled.div`
    min-height: 50px;
    width: 100%;
    padding: 0 16%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.background.pink};
`;

type Props = {
    guest: boolean;
};

const Header: React.FC<Props> = ({ guest }: Props) => {
    const { t } = useTranslation('examinationDetails');

    if (guest)
        return (
            <StyledHeader>
                <LogoSection>
                    <DropdownMenu guest={guest} />
                </LogoSection>
                <MenuSection>
                    <LinkButton bgcolor="#6D6875" to={ROUTES.screening} customHeight="50px">
                        {t('screening')}
                    </LinkButton>
                </MenuSection>
            </StyledHeader>
        );

    return (
        <StyledHeader>
            <LogoSection>
                <img src={Logo} alt="logo" className="logo-img" />
                <DropdownMenu guest={guest} />
            </LogoSection>
            <MenuSection>
                <LinkButton bgcolor="#6D6875" to={ROUTES.mammography_screenings} customHeight="50px">
                    {t('mammography_screening')}
                </LinkButton>
                <LinkButton bgcolor="#6D6875" to={ROUTES.referral} customHeight="50px">
                    {t('referrals')}
                </LinkButton>
            </MenuSection>
        </StyledHeader>
    );
};

export default Header;
