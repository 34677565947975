import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

const Button = styled(AntdButton)`
    white-space: initial;
    height: auto;
    color: ${props => (props.type === 'link' ? props.theme.button.link : props.theme.text.black)} !important;
    background-color: ${props => props.theme.background.theme};
    border-color: ${props => props.theme.background.revertTheme};
    &:hover,
    &:focus {
        > span {
            color: ${props => props.theme.text.selected} !important;
        }
        color: ${props => props.theme.text.selected} !important;
        background-color: ${props => props.theme.background.selected};
    }
`;

export default Button;
