import React from 'react';
import styled from 'styled-components';
import { card as Card, Space, text as Text } from 'components/common';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { login_portal as LoginPortal } from 'utils/constants/routes.json';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    flex: 1;

    ${props =>
        props.theme.background.theme
            ? `
            background-color: ${props.theme.background.theme};
            `
            : `
      background-color: #b8c6db;
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    `};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo-card {
        padding-bottom: 2%;
    }

    .ant-btn {
        color: ${props => props.theme.background.revertTheme};
    }
`;

const InformationContainer: React.FC = ({ children }) => {
    const { t } = useTranslation(['common']);

    return (
        <Container className="container">
            <Card.LogoCard shadow maxWidth="90%">
                <Space direction="column">
                    <>{children}</>
                </Space>
            </Card.LogoCard>
            <Link to={LoginPortal}>
                <Button style={{ marginTop: '10px' }} type="link">
                    <Button type="link">{t('common:return')}</Button>
                </Button>
            </Link>
        </Container>
    );
};

export default InformationContainer;
export { Container };
