import React from "react";

type Props = {
    version: string;
}

const style = { fontWeight: 600 }

const AppVersion = ({version}: Props) => (
    <div className="app-version" style={style}>{version}</div>
);

export default AppVersion;