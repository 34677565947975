import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';

type Props = {
    title?: string | JSX.Element;
    content?: string | JSX.Element;
    footer?: string | JSX.Element;
};
const StyledCard = styled(Card)`
    max-width: 500px;
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin: 2%;

    .ant-card-head {
        border-bottom: none;
        margin-top: 6%;

        .ant-card-head-title {
            font-size: 18px;
            word-break: break-word;
            white-space: initial;
            text-align: center;
        }
    }
    p {
        text-indent: 50px;
    }
    .footer {
        margin-top: 5%;
    }
`;

const TextCard: React.FC<Props> = ({ title, footer, content }: Props) => (
    <StyledCard title={title}>
        <div className="content">{content}</div>
        <div className="footer">{footer}</div>
    </StyledCard>
);

TextCard.defaultProps = {
    content: '',
    footer: '',
    title: '',
};

export default TextCard;
