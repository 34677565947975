import React, { useState, useEffect } from 'react';
import { container, button } from 'components/common';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Skeleton } from 'antd';
import styled from 'styled-components';

type Props = {
    pdf: any;
    title?: string;
    downloadText?: string;
    downloadAction: () => void;
};

const { ExaminationContainer } = container;
const { PrettyButton } = button;

const ScrollableDiv = styled.div`
    max-height: 512px;
    overflow-y: scroll;
`;

const DownloadablePdfSection: React.VFC<Props> = ({ pdf, title, downloadText, downloadAction }) => {
    const [pdfPages, setPdfPages] = useState<number[]>([]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    }, [pdf]);

    const handlePdfLoad = (numPages: number) => {
        const mappedPagesArray = Array.from({ length: numPages }, (_, i) => i + 1);
        setPdfPages(mappedPagesArray);
    };

    return (
        <ExaminationContainer text={title} action={<PrettyButton onClick={downloadAction} label={downloadText} />}>
            <ScrollableDiv>
                <Document
                    file={pdf}
                    onLoadSuccess={({ numPages }) => handlePdfLoad(numPages)}
                    onLoadError={err => console.log(err)}
                    loading={<Skeleton />}>
                    {pdfPages.map(pageNumber => (
                        <Page pageNumber={pageNumber} key={`referral${pageNumber}`} />
                    ))}
                </Document>
            </ScrollableDiv>
        </ExaminationContainer>
    );
};

export default DownloadablePdfSection;
