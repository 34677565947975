import dal from 'dal';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import store from 'store';
import { ActionType } from 'store/reducer';
import ROUTES from 'utils/constants/routes.json';
import { verifyAnonymousPathAllowed } from 'utils/helpers/routes';

const { useStateContext } = store;

type Props = {
    anonymousPaths: string[];
};

const useAuthorization = ({ anonymousPaths }: Props) => {
    const [isAnonymousPathAllowed, setIsAnonymousPathAllowed] = useState(false);
    const [isScreeningsAuthorized, setIsScreeningsAuthorized] = useState(false);
    const { dispatch, state } = useStateContext();
    const location = useLocation();
    const history = useHistory();

    const { isAuthenticated, user } = state;

    useEffect(() => {
        const verifyScreeningsAuthorized = () =>
            [ROUTES.screening, ROUTES.survey, ROUTES.audit].indexOf(location.pathname) !== -1;

        const tryAuthorizeUser = () => {
            dal.user
                .getProfile()
                .then(res => res.data)
                .then((portalUser: IPortalUser) =>
                    dispatch({
                        type: ActionType.SIGN_IN,
                        payload: { user: portalUser },
                    })
                )
                .catch(() => history.push(ROUTES.login_portal));
        };

        const tryAuthorizeScreening = () => {
            dal.screenings
                .getScreeningInfo()
                .then(() =>
                    dispatch({
                        type: ActionType.SIGN_IN,
                    })
                )
                .catch(() => history.push(ROUTES.login_result));
        };

        const authorize = () => {
            if (verifyAnonymousPathAllowed(anonymousPaths, location.pathname)) return;

            if (verifyScreeningsAuthorized()) {
                tryAuthorizeScreening();
            } else {
                tryAuthorizeUser();
            }
        };

        authorize();
        setIsAnonymousPathAllowed(verifyAnonymousPathAllowed(anonymousPaths, location.pathname));
        setIsScreeningsAuthorized(verifyScreeningsAuthorized());
    }, [isAuthenticated, location.pathname]);

    return { isAuthenticated, user, isAnonymousPathAllowed, isScreeningsAuthorized };
};

export default useAuthorization;
