import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { container } from 'components/common';
import { MammographyTable, SingleScreeningView, MammographyTableFilters } from 'components/portalScreening';
import { useTranslation } from 'react-i18next';
import * as dal from 'dal';
import { download } from 'utils/helpers/download';
import { InterceptError, InterceptSuccess } from 'utils/interceptors';
import dayjs, { Dayjs } from 'dayjs';
import CustomSpin from 'components/common/CustomSpin';
import documentTypes from 'utils/constants/documentTypes.json';

type Props = {};

const { ExaminationContainer } = container;

const MammographyScreeningsView: React.FC<Props> = () => {
    const [screenings, setScreenings] = useState<MammographicScreening[]>([]);
    const [displayedScreenings, setDisplayedScreenings] = useState<MammographicScreening[]>([]);
    const [choosenScreeningId, setChoosenScreeningId] = useState<number | null>(null);
    const [screeningResults, setScreeningResults] = useState<IScreeningResult[]>([]);
    const [referralPdf, setReferralPdf] = useState(null);
    const [hasReferral, setHasReferral] = useState<boolean>(false);
    const [hasSurvey, setHasSurvey] = useState<boolean>(false);
    const [isoStatus, setIsoStatus] = useState<IsoStatus>('');
    const [loadingState, setLoadingState] = useState<LoadingState>('');
    const [portalProducts, setPortalProducts] = useState<IPortalProduct[]>([]);

    const { t } = useTranslation(['common', 'examinationDetails', 'notifications']);

    useEffect(() => {
        const fetchScreenings = () => {
            setLoadingState('screenings');
            dal.screenings
                .getAll()
                .then(resp => {
                    setScreenings(resp.data);
                    setDisplayedScreenings(resp.data);
                })
                .finally(() => setLoadingState(''));
        };

        fetchScreenings();
    }, []);

    useEffect(() => {
        const fetchResult = (document: IScreeningResult) =>
            dal.screenings
                .downloadScreeningResultById(choosenScreeningId as number, document.id)
                .then(res => {
                    // zawsze istnieje bo inaczej catch
                    document.file = res.data;
                    // chwilowo przypisujemy typ dokumentu opisywania, dzięki temu wyświetlają się jakiekolwiek dokumenty w aplikacji. Docelowo potrzebujemy ten typ otrzymać z backendu tak, aby odpowiednio rozróżniane były skierowania od badań/opisów.
                    document.type = documentTypes.SCREENING_DESCRIPTION_TYPE;
                })
                .catch(err => {
                    document.file = null;
                })
                .finally(() => {
                    const updatedScreeningResults = [
                        ...screeningResults.filter(screningResult => screningResult.id !== document.id),
                        document,
                    ];
                    setScreeningResults(updatedScreeningResults);
                });

        if (screeningResults.some(r => r.file === undefined)) {
            setLoadingState('screeningResult');
            const promises = [...screeningResults.filter(r => r.file === undefined).map(r => fetchResult(r))];
            Promise.allSettled(promises).finally(() => setLoadingState(''));
        }
    }, [screeningResults]);

    useEffect(() => {
        const getResults = () =>
            dal.screenings
                .getScreeningResultsById(choosenScreeningId as number)
                .then(res => {
                    if (res.data.success === false) throw new Error();

                    const allScreeningResults: IScreeningResult[] = res.data.documents.map((r: any) => {
                        const screeningResult = {
                            id: r.id,
                            name: r.name,
                        };
                        return screeningResult;
                    });

                    setScreeningResults(allScreeningResults);
                })
                .catch(() => setScreeningResults([]));

        const doesScreeningHaveReferral = () =>
            dal.screenings
                .getIfReferralExists(choosenScreeningId as number)
                .then(res => setHasReferral(res.data))
                .catch(() => setHasReferral(false));

        const getSurvey = () =>
            dal.surveys
                .getById(choosenScreeningId as number)
                .then(res => {
                    if (res.status === 204 && !res.data) {
                        setHasSurvey(false);
                    }

                    if (res.data) {
                        setHasSurvey(true);
                    }
                })
                .catch(() => setHasSurvey(false));

        const checkIsIsoReady = () =>
            dal.iso
                .check(choosenScreeningId as number)
                .then(res => setIsoStatus(res.data))
                .catch(() => setIsoStatus(''));

        const fetchProducts = () => {
            dal.products.getProducts().then(res => setPortalProducts(res.data));
        };

        setReferralPdf(null);

        if (choosenScreeningId === null) return;

        setLoadingState('screeningResult');

        Promise.allSettled([
            getResults(),
            doesScreeningHaveReferral(),
            getSurvey(),
            checkIsIsoReady(),
            fetchProducts(),
        ]).finally(() => setLoadingState(''));
    }, [choosenScreeningId]);

    useEffect(() => {
        const fetchRefferal = () => {
            dal.screenings
                .getRefferalById(choosenScreeningId as number)
                .then(res => setReferralPdf(res.data))
                .catch(() => setReferralPdf(null));
        };

        if (!hasReferral) return;

        fetchRefferal();
    }, [hasReferral]);

    const fetchIsoInstruction = () => {
        setLoadingState('isoInstruction');
        dal.banners
            .getIsoInstruction()
            .then(res => download(res.data, 'ISO.pdf'))
            .catch(() => InterceptError(t('examinationDetails:fetch_iso_instruction')))
            .finally(() => setLoadingState(''));
    };

    const fetchIso = () => {
        setLoadingState('iso');
        dal.iso
            .get(choosenScreeningId as number)
            .then(res => download(res.data, 'ISO.iso'))
            .catch(err => {
                if (
                    err?.response?.data &&
                    err.response.data.status === 503 &&
                    err.response.data.detail === 'no_connection'
                )
                    return InterceptError(t('notifications:no_connection'));
                return InterceptError(t('examinationDetails:download_iso_image'));
            })
            .finally(() => setLoadingState(''));
    };

    const generateIso = () => {
        setLoadingState('iso');
        dal.iso
            .generate(choosenScreeningId as number)
            .then(() => {
                InterceptSuccess(t('examinationDetails:iso_request_accepted'));
                setIsoStatus('new');
            })
            .catch(err => {
                if (
                    err?.response?.data &&
                    err.response.data.status === 503 &&
                    err.response.data.detail === 'no_connection'
                )
                    return InterceptError(t('notifications:no_connection'));
                if (
                    err?.response?.data &&
                    err.response.data.status === 500 &&
                    err.response.data.detail === 'iso_generator_full_status'
                )
                    return InterceptError(t('notifications:iso_generator_full'));
                return InterceptError(t('notifications:generate_iso_file_error'));
            })
            .finally(() => setLoadingState(''));
    };

    const handleFilter = (
        date: [Dayjs, Dayjs] | null,
        descriptionFinished: string,
        results: number[],
        screeningId: string
    ) => {
        const filtered = screenings.filter(entry => {
            if (date) {
                const formatted = dayjs(entry.screening.createdAt).format('MM/DD/YYYY');

                if (
                    !(
                        dayjs(formatted).diff(date[0].format('MM/DD/YYYY')) >= 0 &&
                        dayjs(formatted).diff(date[1].format('MM/DD/YYYY')) <= 0
                    )
                )
                    return false;
            }

            if (descriptionFinished) {
                const descriptionStatus = entry.screening.screeningDescriptionsStatus;
                if (descriptionFinished === 'yes' && descriptionStatus !== 'desc_all_finished') return false;

                if (descriptionFinished === 'no' && descriptionStatus === 'desc_all_finished') return false;
            }

            if (results.length > 0) {
                const result = entry.descriptions[0].wynik || 0;

                if (results.indexOf(+result) === -1) return false;
            }

            if (screeningId && entry.screening.id !== +screeningId) return false;

            return true;
        });

        setDisplayedScreenings(filtered);
    };

    const handleClear = () => setDisplayedScreenings(screenings);

    return (
        <div>
            {loadingState === 'screenings' ? (
                <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />
            ) : (
                <ExaminationContainer
                    text={t('examinationDetails:mammography_screening')}
                    action={<MammographyTableFilters handleFilter={handleFilter} handleClear={handleClear} />}>
                    <Card>
                        <MammographyTable
                            screenings={displayedScreenings}
                            onRowSelect={(id: number | null) => setChoosenScreeningId(id)}
                            choosenScreeningId={choosenScreeningId}
                        />
                    </Card>
                </ExaminationContainer>
            )}
            {loadingState === 'screenings' ? (
                <CustomSpin containerStyles={{ width: '100%', height: '300px' }} />
            ) : (
                <SingleScreeningView
                    loadingState={loadingState}
                    type="patientPortal"
                    screeningId={choosenScreeningId}
                    fetchResult={download}
                    fetchReferral={() => download(referralPdf, 'skierowanie.pdf')}
                    fetchIsoInstruction={fetchIsoInstruction}
                    fetchIso={fetchIso}
                    portalProducts={portalProducts}
                    resultsPdfs={screeningResults}
                    referralPdf={referralPdf}
                    hasSurvey={hasSurvey}
                    isoStatus={isoStatus}
                    generateIso={generateIso}
                />
            )}
        </div>
    );
};

export default MammographyScreeningsView;
