import React from 'react';
import { Col, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from 'components/common';

type Props = {
    onSelect: (values: number[]) => void;
    selected: any[];
};

const ResultCheckbox: React.VFC<Props> = ({ onSelect, selected }: Props) => {
    const [t] = useTranslation('common');

    const displayOptions = () => {
        const values = [
            { value: 1, title: t('result_normal') },
            { value: 2, title: t('delicate_change') },
            { value: 3, title: t('possibly_delicate_change') },
            { value: 4, title: t('suspicious_change') },
            { value: 5, title: t('malcious_change') },
            { value: -1, title: t('needs_more_checks') },
        ];

        return values.map(({ value, title }) => (
            <Col xs={24} sm={12}>
                <Checkbox key={`${value}-${title}`} value={value}>
                    {title}
                </Checkbox>
            </Col>
        ));
    };

    return <CheckboxGroup onSelect={onSelect} options={displayOptions()} selected={selected} />;
};

export default ResultCheckbox;
