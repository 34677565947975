import SurveyQuestion from 'interfaces/SurveyQuestion';
import { useTranslation } from 'react-i18next';

const useQuestions = () => {
    const { t } = useTranslation('surveys');

    const [a, b, c, d, e, f, g, h] = t('questions', { returnObjects: true });
    const questions: SurveyQuestion[] = [
        {
            answerValue: null,
            question: a,
            key: 1,
            reversed: false,
        },
        {
            answerValue: null,
            question: b,
            key: 2,
            reversed: false,
        },
        {
            answerValue: null,
            question: c,
            key: 3,
            reversed: false,
        },
        {
            answerValue: null,
            question: d,
            key: 4,
            reversed: false,
        },
        {
            answerValue: null,
            question: e,
            key: 5,
            reversed: false,
        },
        {
            answerValue: null,
            question: f,
            key: 6,
            reversed: true,
        },
        {
            answerValue: 0,
            question: g,
            key: 7,
            accessDefinerOf: 8,
            reversed: false,
        },

        {
            answerValue: null,
            question: h,
            key: 8,
            definedBy: 7,
            reversed: true,
        },
    ];
    return { questions };
};

export default useQuestions;
