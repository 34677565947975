import React, { useContext } from 'react';

interface AppVersionContext {
    currentVersion: string;
}

const VersionContext = React.createContext<AppVersionContext>({
    currentVersion: `v. 2.66.12b23be9, 18.06.2024`,
});

export const useAppVersion = () => useContext(VersionContext);
