import { api } from '../api';

export const getScreeningResults = () => api.get('/screening/result');

export const getScreeningResultsById = (id: number) => api.get('/screening/result', { params: { id } });

export const downloadScreeningResult = (documentId: number) =>
    api.get('/screening/download-result', { params: { documentId }, responseType: 'blob' });

export const downloadWykaz = () => api.get(`/screening/download-wykaz`, { responseType: 'blob' });

export const downloadScreeningResultById = (id: number, documentId: number) =>
    api.get('/screening/download-result', { params: { screeningId: id, documentId }, responseType: 'blob' });

export const getAll = () => api.get('/screenings');

export const getRefferal = () => api.get('/screening/refferal', { responseType: 'blob' });

export const getRefferalById = (id: number) => api.get('/screening/refferal', { params: { id }, responseType: 'blob' });

export const getScreeningInfo = () => api.get(`/screening`);

export const getScreeningById = (id: number) => api.get(`/screening`, { params: { id } });

export const getIfReferralExists = (id: number) => api.get(`/screening/${id}/referral/exists`);

export const getRefferals = () => api.get('/screenings/referrals');
