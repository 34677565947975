import React from 'react';
import { Card, Row, Col, Divider } from 'antd';
import { useStateContext } from 'store/state';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReadOnlyInput } from 'components/common/input';

const ProfileDetailsCard: React.FC = () => {
    const {
        state: {
            user: { personalData, email },
        },
    } = useStateContext();
    const { t, i18n } = useTranslation('profileDetails');
    return (
        <Card>
            <Divider> {t('fullname')} </Divider>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput value={personalData?.firstName || ''} />
                </Col>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput value={personalData?.lastName || ''} />
                </Col>
            </Row>
            <Divider> {t('birth_date_pesel')} </Divider>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput
                        value={
                            personalData?.birthAt
                                ? dayjs(personalData?.birthAt).locale(i18n.language).format('DD MMMM YYYY')
                                : ''
                        }
                    />
                </Col>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput value={personalData?.pesel} />
                </Col>
            </Row>
            <Divider> {t('email_phone')}</Divider>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput value={personalData?.email || email || ''} />
                </Col>
                <Col xs={24} sm={24} xl={12}>
                    <ReadOnlyInput value={personalData?.mobileNumber} />
                </Col>
            </Row>
        </Card>
    );
};

export default ProfileDetailsCard;
