/* eslint-disable indent */
// @ts-nocheck

import { layoutWrappedPaths } from 'App';
import { useLocalStorage } from 'hooks';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = () => {
    const [value] = useLocalStorage('size', '1');
    const location = useLocation();
    const StyledGlobalStyles = createGlobalStyle`
    

  .ant-card , .ant-card-body, .ant-card-head {
    background-color: ${props => props.theme.background.theme || 'white'} !important;
    color: ${props => props.theme.text.black};
    label, button > span {
      color: ${props => props.theme.background.revertTheme};
    }
  }

  .ant-pagination-simple-pager {
    input {
        background: ${props => props.theme.background.theme} !important ;
        border-color: ${props => props.theme.background.border}  !important;
        color: ${props => props.theme.text.black};
    }
  }

  .ant-dropdown-menu {
        padding: 0;
        background-color: ${props => props.theme.background.theme} !important;
    }


  .ant-divider-inner-text {
    color: ${props => props.theme.text.black};
  }

  .ant-input, .ant-input-password {
    background: ${props => props.theme.background.theme} !important ;
    border-color: ${props => props.theme.background.border}  !important;
    color: ${props => props.theme.text.black};

    &::placeholder {
      color: ${props => props.theme.text.black};
    }

    &:disabled {
      color: ${props => props.theme.background.revertTheme};
    }
  }

  .ant-card-bordered {
    border-color: ${props => props.theme.background.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${props => props.theme.background.theme} !important ;
    border-color: ${props => props.theme.background.border}  !important;
  }

  .ant-slider-rail {
    background-color: ${props => props.theme.background.revertTheme || '#f5f5f5'};
  }

  .ant-slider-track {
    background-color: ${props => props.theme.background.revertTheme || '#91d5ff'};
  }
  .ant-radio-inner {
    &::after {
      background-color: #000000;
    }
  }
  .ant-notification-notice {
    background-color: ${props => props.theme.background.theme || 'white'} !important;
    .ant-notification-notice-message {
      color: ${props => props.theme.background.revertTheme};
    }
  }

  tr {
        background-color: ${props => props.theme.background.theme} !important;
        color: ${props => props.theme.text.black};
        th {
          border-color: ${props => props.theme.background.border} !important;
        }
    }

   h1, h2, b, p, li, svg  {
    color: ${props => props.theme.background.revertTheme || 'inherit'};
  }

  .accessibility-btn {
    background: ${props => props.theme.background.theme} !important ;
    border-color: ${props => props.theme.background.revertTheme || ''}  !important;
    color: ${props => props.theme.text.black};
  }

  ${
      !layoutWrappedPaths.includes(location.pathname) &&
      `.container {
    padding-top: ${110 * value}px !important;
  }`
  }
  

  .ant-typography {
    color: ${props => props.theme.background.theme || ''}  !important;
  }

  span.ant-radio + *,  .ant-result-title {
    color: ${props => props.theme.text.black} !important;
  }

  .ant-typography-danger {
    color: ${props => props.theme.background.revertTheme || 'ff4d4f'} !important;
  }

  .ant-popover-inner {
        background-color: ${props => props.theme.background.theme} !important;
        .ant-checkbox-wrapper, .ant-radio-wrapper , .popover-content span {
          color: ${props =>
              props.theme.name !== 'default' ? `${props.theme.text.black} !important` : props.theme.text.black};
          &:hover {
            color: ${props => props.theme.background.theme} !important;
          }
        }
       .ant-picker {
         background-color: ${props => props.theme.background.theme} !important;
         border: ${props => props.theme.background.revertTheme} !important;
         input {
           &::placeholder {
             color: ${props => props.theme.text.black} !important;
           }
           color: ${props => props.theme.text.black} !important;
         }
       }
  }
  .ant-picker-body {
    .ant-picker-cell {
      color: ${props => props.theme.background.revertTheme || 'inherit'};
    }
  }

  .ant-picker-header {
    .ant-picker-header-view, button, .ant-picker-cell-inner {
      color: ${props => props.theme.background.revertTheme || 'inherit'};
    }
  }
  .ant-btn {
    word-break: break-word;
    white-space: initial;
  }

  .ant-picker-date-panel {
    background-color: ${props => props.theme.background.theme} !important;
  }

  //----------------------------------------------------------------------------
  //---------------------------------- FONT SIZE -------------------------------
  //----------------------------------------------------------------------------

  .svg-x-large {
    svg {
      font-size: ${() => 70 * value}px !important;
    }
  }

  .ant-card-head-title {
    font-size: ${() => 18 * value}px !important;
  }

  h1, .font-h1 {
    font-size: ${() => 18 * value}px !important;
  }

  .h0 {
    font-size: ${() => 40 * value}px !important;
  }

  h2, .font-h2 {
    font-size: ${() => 1.5 * value}em !important;
  }

  h3, .font-h3 {
    font-size: ${() => 1.17 * value}em !important;
  }
  p, input, span, td, th, button, li, label, .ant-typography {
    font-size: ${() => 14 * value}px !important;
  }

  .reset-password-svg {
    svg {
      font-size: ${() => 180 * value}px !important;
    }
  }

  .anticon-smile svg, .anticon-frown svg {
    font-size: ${() => 30 * value}px !important;
  }



  .tie-body {
        font-size: ${() => 16 * value}px !important;
        color: #595959;

        @media (min-width: 499px) {
          font-size: ${() => 22 * value}px !important;
        }
    }
  }
`;

    return <StyledGlobalStyles />;
};

export { GlobalStyles };
export default GlobalStyles;
