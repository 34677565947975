import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';

type Props = {
    children?: React.ReactChild;
    onClick?: () => void;
    customHeight?: string;
    to: string;
    bgcolor?: string;
};

type StyledProps = {
    height?: string;
    bgcolor: string;
};

const StyledButton = styled(Button)<StyledProps>`
    background: none;
    height: ${(props: StyledProps) => props.height || 'initial'};
    border: none;
    min-width: 200px;
    max-height: 60px;
    text-align: center;
    color: ${props => props.theme.text.lavender};
    &:hover,
    &:focus {
        color: ${props => props.theme.text.selected};
        background-color: ${props => props.theme.background.selected || props.bgcolor};
    }
`;

const LinkButton: React.FC<Props> = ({ children, onClick, customHeight, to, bgcolor = 'inherit' }: Props) => {
    const history = useHistory();
    const customOnClick = () => {
        if (onClick) onClick();
        history.push(to);
    };

    return (
        <StyledButton bgcolor={bgcolor} height={customHeight} type="primary" onClick={customOnClick}>
            {children}
        </StyledButton>
    );
};

LinkButton.defaultProps = {
    children: '',
    onClick: () => {},
    customHeight: undefined,
};

export default LinkButton;
