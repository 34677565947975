import React from 'react';
import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

type Props = {
    children?: React.ReactChild | React.ReactChild[];
    customWidth?: string;
    htmlType?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: () => void;
    loading?: boolean;
    className?: string;
};

type StyleProps = {
    customWidth?: string;
} & ButtonProps;

const StyledButton = styled(Button)<StyleProps>`
    width: ${(props: StyleProps) => props.customWidth};
    height: 50px;
    border-radius: 30px;
    margin: 1%;
    color: ${props => props.theme.text.white};
    background-color: ${props => props.theme.background.theme || ' #457b9d'};
    border: ${props =>
        props.theme.background.revertTheme ? `1px solid ${props.theme.background.revertTheme}` : 'none'};

    &:hover,
    &:active,
    &:focus {
        border: none;
        color: ${props => props.theme.background.theme} !important;
        background-color: ${props => props.theme.background.onHoverColor || ' #016f90'};
    }
`;

const DefaultButton: React.FC<Props> = ({
    children,
    customWidth = '50%',
    htmlType,
    onClick,
    loading = false,
    className,
}: Props) => (
    <StyledButton
        className={className}
        loading={loading}
        customWidth={customWidth}
        type="default"
        htmlType={htmlType}
        onClick={onClick}>
        {children}
    </StyledButton>
);

DefaultButton.defaultProps = {
    children: <></>,
    customWidth: '50%',
    htmlType: undefined,
    onClick: () => null,
};

export default DefaultButton;
