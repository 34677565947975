import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { state } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const { StateProvider } = state;
ReactDOM.render(
    <Router>
        <React.StrictMode>
            <StateProvider>
                <App />
            </StateProvider>
        </React.StrictMode>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
