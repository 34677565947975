import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import logo from 'assets/img/logo.png';
import { useTranslation } from 'react-i18next';
import { PrettyButton } from '../button';

type Props = {
    children?: React.ReactNode;
    bordered?: boolean;
    authManagement?: boolean;
    onButtonClick?: () => void;
    maxWidth?: string;
    shadow?: boolean;
    background?: string;
};

type CardProps = {
    shadow: boolean;
    maxWidth: string;
    background?: string;
};

const StyledCard = styled(Card)<CardProps>`
    max-width: ${props => props.maxWidth};
    padding: 10px;
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    box-shadow: ${props => (props.shadow ? '10px 10px 5px 0px rgb(0, 0, 0, );' : 'none')};
    background: ${props => props.background || '#fff'};

    .ant-card-head-title {
        display: flex !important;
        margin-top: 4%;
        justify-content: center;
    }

    .ant-card-head {
        border-bottom: none;
        background: #fff;
    }
    .ant-card-body {
        display: flex;
        flex: 1;
    }
`;

const Logo = styled.img`
    max-width: 500px;
    @media screen and (max-width: 450px) {
        width: 100%;
    }
`;

const PrettyButtonStyles = {
    position: 'absolute',
    right: '5px',
    top: '5px',
    borderRadius: '10px',
};

const LogoCard: React.FC<Props> = ({
    children,
    bordered,
    authManagement = false,
    onButtonClick,
    maxWidth,
    shadow = false,
    background,
}: Props) => {
    const { t } = useTranslation('common');

    const Title = () => (
        <>
            {!!authManagement && (
                <PrettyButton style={PrettyButtonStyles} label={t('log_out')} onClick={onButtonClick} />
            )}
            <Logo src={logo} alt="logo_front" />
        </>
    );

    return (
        <StyledCard
            shadow={shadow}
            className="logo-card"
            title={<Title />}
            bordered={bordered}
            maxWidth={maxWidth as string}
            background={background}>
            {children}
        </StyledCard>
    );
};

LogoCard.defaultProps = {
    children: <></>,
    bordered: false,
    authManagement: false,
    onButtonClick: () => null,
    maxWidth: '600px',
    shadow: false,
};

export default LogoCard;
