import React, { useContext, useReducer } from 'react';
import { reducer, Action } from './reducer';

export interface StateContext {
    isAuthenticated: boolean;
    user: IPortalUser;
}
export interface NullableStateContext {
    isAuthenticated?: boolean;
    user?: IPortalUser;
}

export interface Store {
    state: StateContext;
    dispatch: React.Dispatch<Action>;
}

const data: IPortalUser = {
    email: '',
    id: 0,
    password: '',
    personalDataId: 0,
};

const defaultState: StateContext = { isAuthenticated: false, user: data };

const Context = React.createContext<Store>({ state: defaultState, dispatch: () => {} });

export const useStateContext = () => useContext(Context);

type StateProviderProps = {
    children: React.ReactChild | React.ReactChild[];
};

export const StateProvider: React.FC<StateProviderProps> = ({ children }: StateProviderProps) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};
