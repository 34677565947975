import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { button, card, Notification } from 'components/common';
import { Form, Input } from 'antd';
import * as dal from 'dal';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import ROUTES from 'utils/constants/routes.json';

const { LogoCard } = card;
const { DefaultButton } = button;
const { openNotificationWithIcon } = Notification;

const Container = styled.div`
    flex: 1;
    background-color: #b8c6db;
    ${props =>
        props.theme.background.theme
            ? `
            background-color: ${props.theme.background.theme};
            `
            : `
      background-color: #b8c6db;
      background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);
    `}
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    form {
        width: 100%;
    }
    input {
        height: 60px;
    }
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
    }
`;

const ResetPasswordView: React.FC = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation(['passwordManagement']);

    const onFinish = (e: any) => {
        dal.auth
            .resetPassword(e, i18n.language)
            .then(() => history.push(ROUTES.reset_password_link_sent))
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    openNotificationWithIcon({ type: 'error', message: t('passwordManagement:EMAIL_NOT_FOUND') });
                }
            });
    };

    return (
        <Container className="container">
            <LogoCard>
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={() => {}}>
                    <Form.Item
                        name="email"
                        validateStatus="error"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t('passwordManagement:invalid_email'),
                            },
                        ]}>
                        <Input className="font-h2" size="large" placeholder="Email..." />
                    </Form.Item>
                    <Form.Item>
                        <DefaultButton className="font-h2" htmlType="submit">
                            {t('passwordManagement:retrieve_password')}
                        </DefaultButton>
                    </Form.Item>
                </Form>
            </LogoCard>
        </Container>
    );
};

export default ResetPasswordView;
