import React from 'react';
import styled from 'styled-components';
import { Popover as AntdPopover, PopoverProps } from 'antd';

const StyledPopover = styled(AntdPopover)`
    .ant-popover-inner {
        background-color: ${props => props.theme.background.theme} !important;
    }
`;

const Popover: React.FC<PopoverProps> = (props: PopoverProps) => (
    <StyledPopover  {...props}> {props.children}</StyledPopover>
);

export default Popover;
