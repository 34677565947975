import React from 'react';
import { Button, Dropdown, Menu, Divider } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, UserOutlined, FileAddOutlined, AuditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import store from 'store';
import dal from 'dal';
import { ActionType } from 'store/reducer';
import { useTranslation } from 'react-i18next';
import ROUTES from 'utils/constants/routes.json';

const StyledDropdownMenu = styled.div`
    margin: 0 auto;

    button {
        padding: 0;
        margin: 0 auto;
        width: 100%;
        color: ${props => props.theme.text.lavender} !important;
    }

    @media screen and (max-width: 1320px) {
        margin: 15px;
        flex-basis: 100%;
        width: 100vw;
        display: flex;
        .desktop {
            display: none;
        }
    }
    @media screen and (min-width: 1320px) {
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        .mobile {
            display: none;
        }
    }
`;

const StyledMenu = styled(Menu)`
    padding: 0 !important;

    border: 1px solid ${props => props.theme.background.revertTheme || 'none'};
    li {
        padding: 5px 0;
        background-color: ${props => props.theme.background.theme} !important;
        text-align: start;

        &:hover {
            background-color: ${props => props.theme.background.revertTheme} !important;

            span,
            span > svg {
                color: ${props => props.theme.background.theme} !important;
            }
        }
        span,
        span > svg {
            color: ${props => props.theme.text.black} !important;
        }
    }
    button {
        width: 100%;
        padding-left: 40px;
        text-align: start;
    }
`;

const StyledDivider = styled(Divider)`
    margin: 0px;
`;

const { useStateContext } = store;

type Props = {
    guest: boolean;
};

const DropdownMenu: React.FC<Props> = ({ guest }: Props) => {
    const { state, dispatch } = useStateContext();
    const {
        user: { personalData, email },
    } = state;
    const history = useHistory();
    const { t } = useTranslation(['common', 'audit']);

    const onLogout = () => {
        dal.auth
            .logout()
            .then(() => dispatch({ type: ActionType.SIGN_OUT }))
            .then(() => history.push(ROUTES.login_portal));
    };

    const getPersonalData = () => {
        if (personalData) return `${personalData?.firstName} ${personalData?.lastName}`;

        if (!personalData && email) return `${email}`;

        return t('common:guest').toUpperCase();
    };

    const guestMenu = (
        <StyledMenu>
            <Menu.Item>
                <Link to={ROUTES.audit}>
                    <Button type="text" icon={<AuditOutlined />}>
                        {t('audit:audit')}
                    </Button>
                </Link>
            </Menu.Item>

            <StyledDivider />

            <Menu.Item>
                <Button onClick={onLogout} type="text" icon={<LogoutOutlined />}>
                    {t('common:log_out')}
                </Button>
            </Menu.Item>
        </StyledMenu>
    );

    const menu = (
        <StyledMenu>
            <Menu.Item>
                <Link to={ROUTES.profile_portal}>
                    <Button type="text" icon={<UserOutlined />}>
                        {t('common:my_data')}
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={ROUTES.upload}>
                    <Button type="text" icon={<FileAddOutlined />}>
                        {t('common:upload_screenings')}
                    </Button>
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={ROUTES.portal_audit}>
                    <Button type="text" icon={<AuditOutlined />}>
                        {t('audit:audit')}
                    </Button>
                </Link>
            </Menu.Item>

            <StyledDivider />

            <Menu.Item>
                <Button onClick={onLogout} type="text" icon={<LogoutOutlined />}>
                    {t('common:log_out')}
                </Button>
            </Menu.Item>
        </StyledMenu>
    );
    return (
        <StyledDropdownMenu>
            <Dropdown
                className="desktop"
                overlay={guest ? guestMenu : menu}
                trigger={['click', 'hover']}
                placement="bottomRight">
                <Button icon={<DownOutlined />} type="link">
                    {getPersonalData()}
                </Button>
            </Dropdown>
            <Dropdown
                className="mobile"
                overlay={guest ? guestMenu : menu}
                trigger={['click', 'hover']}
                placement="bottomRight">
                <Button icon={<DownOutlined />} type="link">
                    {getPersonalData()}
                </Button>
            </Dropdown>
        </StyledDropdownMenu>
    );
};

export default DropdownMenu;
