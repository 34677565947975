import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Row, Checkbox } from 'antd';

type Props = {
    onSelect: (values: any[]) => void;
    options?: JSX.Element[];
    selected?: any[];
};

const ResultCheckbox: React.VFC<Props> = ({ onSelect, options = [], selected }: Props) => {
    const onCheckboxChange = (values: any[]) => {
        onSelect(values);
    };

    return (
        <Checkbox.Group value={selected} onChange={(values: CheckboxValueType[]) => onCheckboxChange(values as any[])}>
            <Row>{options}</Row>
        </Checkbox.Group>
    );
};

export default ResultCheckbox;
