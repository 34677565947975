import { Table as AntTable } from 'antd';
import styled from 'styled-components';

const StyledTable = styled(AntTable)`
    width: 100%;
    .ant-table {
        overflow: auto;
        background: ${props => props.theme.background.white} !important;
    }

    .ant-table-thead > tr > th {
        color: ${props => props.theme.text.white};
        background: ${props => props.theme.background.pink} !important;
        word-break: keep-all;
    }
    .ant-table-tbody > tr > td {
        border-bottom: none;
    }

    .ant-table-tbody > tr > th {
        color: ${props => props.theme.text.white};
        background: ${props => props.theme.background.pink} !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: unset;
        cursor: pointer;
    }
    .selected-row {
        background-color: ${props => props.theme.background.selected || props.theme.background.melon} !important;
        color: ${props => props.theme.text.selected || props.theme.text.white};
    }
    .ant-table-container {
        border: 1px solid ${props => props.theme.background.selected || props.theme.background.pink};
        min-width: max-content;
    }
`;

export default StyledTable;
