import React from 'react';
import { Row, Col, Typography } from 'antd';
import IconSlider from './IconSlider';

type QuestionCardProps = {
    index: number;
    question: string;
    handleChange: (index: number, value: number) => void;
    value: number | undefined;
    reversed: boolean;
    disabled?: boolean;
};

const QuestionCard: React.FC<QuestionCardProps> = ({
    question,
    handleChange,
    index,
    value,
    disabled,
    reversed,
}: QuestionCardProps) => (
    <Row justify="center" style={{ width: '100%', margin: '50px 0' }}>
        <Col xs={19} md={8} style={{ textAlign: 'start' }}>
            <Typography.Title level={5}>{question}</Typography.Title>
        </Col>
        <Col xs={18} md={12}>
            <IconSlider
                index={index}
                min={1}
                max={5}
                value={value}
                handleChange={handleChange}
                disabled={disabled || false}
                reversed={reversed}
            />
        </Col>
    </Row>
);

QuestionCard.defaultProps = {
    disabled: false,
};

export default QuestionCard;
