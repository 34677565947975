import React from 'react';
import styled from 'styled-components';

type Props = {
    children?: React.ReactChild | React.ReactChild[];
    textAlign?: TextAlign;
};

type StyleProps = {
    textAlign?: string;
};

const Container = styled.div<StyleProps>`
    text-align: ${(props: StyleProps) => props.textAlign || 'initial'};
`;

const TextContainer: React.FC<Props> = ({ children, textAlign }: Props) => (
    <Container className="text-container container" textAlign={textAlign}>
        {children}
    </Container>
);

TextContainer.defaultProps = {
    children: <></>,
    textAlign: 'initial',
};

export default TextContainer;
