import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import styled from 'styled-components';

interface IProps extends CheckboxProps {}

const StyledCheckbox = styled(Checkbox)<IProps>`
    &, span {
        font-size: 1rem;
        line-height: 1rem;
        white-space: pre-line;
    }

    &, &:hover, &:active, &:focus {
        color: ${props => props.theme.text.selected};
        outline: none;

        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: ${props => props.theme.background.selected};
                border-color: ${props => props.theme.background.border};

                &:after {
                    border-color: ${props => props.theme.background.border};
                }
            }
        }

        .ant-checkbox-inner {
            background-color: ${props => props.theme.background.body};
            border-color: ${props => props.theme.background.border};
        }

        .ant-checkbox-checked::after {
            border: none;
        }
    }

    @media screen and (max-width: 992px) {
        margin: 10px 0px;
        width: 100%;
    }
`;

const CustomCheckbox: React.FC<IProps> = props => (
    <StyledCheckbox {...props}>
        {props.children}
    </StyledCheckbox>
);

export default CustomCheckbox;
