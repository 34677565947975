import React from 'react';
import styled from 'styled-components';
import ChangeLanguageSection from './ChangeLanguageSection';
import ContrastSection from './ContrastSection';
import SizeSection from './SizeSection';
import ResetSection from './ResetSection';

type Props = {
    style?: React.CSSProperties;
};

const AccessibilityContainer = styled.div<Props>`
    justify-content: start;
    flex-direction: column;
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: 1320px) {
        top: 0px !important;
        flex-direction: row !important;
        align-items: flex-start !important;
    }

    .size-lang-section {
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    .contrast-reset-section {
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    @media (min-width: 1320px) {
        .size-lang-section {
            display: block;
        }
        .contrast-reset-section {
            display: block;
        }
    }
`;

const AccessibilitySection: React.FC<Props> = ({ style }) => (
    <AccessibilityContainer style={style}>
        <div className="size-lang-section">
            <ChangeLanguageSection />
            <SizeSection />
        </div>
        <div className="contrast-reset-section">
            <ResetSection />
            <ContrastSection />
        </div>
    </AccessibilityContainer>
);

export default AccessibilitySection;
