import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
    containerStyles: any;
    tip?: string;
};

const StyledSpin = styled(Spin)`
    font-size: 20px;
    position: relative;
    top: 35%;
    margin: 0 auto;
    display: block;
    color: #8c8c8c;

    .anticon.anticon-spin {
        font-size: 48px;
        color: #8c8c8c;
    }
`;

const CustomSpin = ({ containerStyles, tip }: Props) => {
    const { t } = useTranslation('common');
    return (
        <div style={containerStyles}>
            <StyledSpin tip={tip || t('loading')} indicator={<LoadingOutlined spin />} />
        </div>
    );
};

export default CustomSpin;
