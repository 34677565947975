import styled from 'styled-components';
import React from "react";
import { button } from 'components/common';

const { Button } = button;

const StyledWrap = styled('div')`
    width: 280px;
    position: fixed;
    top: 50%;
    right: 1%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid ${props => props.theme.text.black};
    border-radius: 10px;
    z-index: 1000;
    background-color: ${props => props.theme.background.theme === '' ? '#fff' : props.theme.background.theme};
    
    .spout {
        height: 30px;
        width: 30px;
        position: absolute;
        left: -16.5px;
        top: 50%;
        border-left: 2px solid ${props => props.theme.text.black};
        border-top: 2px solid ${props => props.theme.text.black};
        transform: rotate(-45deg);
        background-color: ${props => props.theme.background.theme === '' ? '#fff' : props.theme.background.theme};
        z-index: -1;
    }
    
    .content {
        text-align: justify;
        z-index: 10;
    }
    
    .button_icon_container {
        display: flex;
        justify-content: space-between;
    }
    
    .button_container {
        display: flex;
        align-items: flex-end;
    }
`;

type Props = {
    buttonText: string;
    image: string;
    textTitle: any;
    textContent: string;
    onClick: () => void;
    display: boolean;
}

const FloatingBubble = ({ buttonText, image, textTitle, textContent, onClick, display}: Props) => {
    if (!display)
        return <></>;

    return (
        <StyledWrap>
            <div className="spout"/>
            <div>
                <p>{textTitle}</p>
                <p className="content">{textContent}</p>
            </div>
            <div className="button_icon_container">
                <div>
                    <img src={image} alt='doctor' width={80}/>
                </div>
                <div className="button_container">
                    <Button onClick={onClick}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </StyledWrap>
    )
}

export default FloatingBubble;