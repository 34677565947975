import { detect } from 'detect-browser';

const getBrowserDetails = () => {
    const browser = detect();
    if (browser) {
        return {
            name: browser.name,
            os: browser.os,
            version: browser.version,
        };
    }
    return {
        name: null,
        os: null,
        version: null,
    };
};

export { getBrowserDetails };
