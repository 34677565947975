import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
    name: 'default',
    background: {
        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        theme: '',
        revertTheme: '',
        apricot: '#FFCDB2',
        melon: '#FFB4A2',
        pink: '#E5989B',
        lavender: '#6D6875',
        white: '#FFFFFF',
        peach: '#FFBE9C',
        border: '#f0f0f0',
        selected: '#e5989b',
    },
    text: {
        white: '#FFFFFF',
        black: '#000000',
        lavender: '#6D6875',
        selected: false,
    },
    button: {
        link: '#1890ff',
    },
};

export const whiteContrast: DefaultTheme = {
    name: 'whiteContrast',
    background: {
        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        theme: '#FFFFFF',
        revertTheme: '#000000',
        apricot: '#FFFFFF',
        melon: '#FFFFFF',
        pink: '#FFFFFF',
        lavender: '#FFFFFF',
        white: '#FFFFFF',
        peach: '#000000',
        border: '#000000',
        selected: '#000000',
        onHoverColor: '#FFF'
    },
    text: {
        white: '#000000',
        black: '#000000',
        lavender: '#000000',
        selected: '#FFFFFF',
    },
    button: {
        link: '#000000',
    },
};

export const blackContrast: DefaultTheme = {
    name: 'blackContrast',
    background: {
        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        theme: '#000000',
        revertTheme: '#FFFFFF',
        apricot: '#000000',
        melon: '#000000',
        pink: '#000000',
        lavender: '#FFFFFF',
        white: '#000000',
        peach: '#FFFFFF',
        border: '#FFFFFF',
        selected: '#FFFFFF',
        onHoverColor: '#000000'
    },
    text: {
        white: '#FFFFFF',
        black: '#FFFFFF',
        lavender: '#FFFFFF',
        selected: '#000000',
    },
    button: {
        link: '#FFFFFF',
    },
};

export const yellowContrast: DefaultTheme = {
    name: 'yellowContrast',
    background: {
        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        theme: '#FFFF00',
        revertTheme: '#000000',
        apricot: '#FFFF00',
        melon: '#FFFF00',
        pink: '#FFFF00',
        lavender: '#FFFF00',
        white: '#FFFF00',
        peach: '#000000',
        border: '#000000',
        selected: '#000000',
        onHoverColor: '#FFFF00'
    },
    text: {
        white: '#000000',
        black: '#000000',
        lavender: '#000000',
        selected: '#FFFF00',
    },
    button: {
        link: '#000000',
    },
};

export const yellowBlackContrast: DefaultTheme = {
    name: 'yellowBlackContrast',
    background: {
        body: '#FFF',
        text: '#363537',
        toggleBorder: '#FFF',
        theme: '#000000',
        revertTheme: '#FFFF00',
        apricot: '#000000',
        melon: '#000000',
        pink: '#000000',
        lavender: '#000000',
        white: '#000000',
        peach: '#FFFF00',
        border: '#FFFF00',
        selected: '#FFFF00',
        onHoverColor: '#000000'
    },
    text: {
        white: '#FFFF00',
        black: '#FFFF00',
        lavender: '#FFFF00',
        selected: '#000000',
    },
    button: {
        link: '#FFFF00',
    },
};

export const accessibilityThemes = {
    default: defaultTheme,
    'black-white': whiteContrast,
    'white-black': blackContrast,
    'black-yellow': yellowContrast,
    'yellow-black': yellowBlackContrast,
};
